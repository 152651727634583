import React, { useContext, useEffect, useState } from "react";
import { useAsync } from "../hooks/useAsync";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { ScreenTitle } from "../components/ScreenTitles";
import { Loader } from "../components/Loader";
import { getSuccessToast } from "../components/Toasts";
import { RFC } from "../sharedTypes";
import { apiManager } from "../network/apiManager";
import { UserContext } from "../context/UserContextProvider";
import theme from "../theme";
import { ResetPasswordSchema } from "../forms/schemas/resetPasswordSchema";
import { navigateUserBasedOnRole } from "../navigation/navigationUtils";
import { FormikForm } from "../components/FormikForm";
import { resetPasswordFields } from "../forms/fields/resetPasswordFields";
import { useLocalError } from "../hooks/useLocalError";

const ResetPassword: RFC = () => {
  const toast = useToast();
  const {
    currentUserInfo,
    setToken,
    wrongUserType,
    setWrongUserType
  } = useContext(UserContext);
  const [userSettingLoading, setUserSettingLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      toast.closeAll();
    };
  }, []);

  useEffect(() => {
    if (currentUserInfo) {
      setUserSettingLoading(false);
      navigateUserBasedOnRole(currentUserInfo);
    }
  }, [currentUserInfo]);

  const resetPasswordExecution = async (values: {
    email: string;
    newPassword: string;
    confirmPassword: string;
    resetCode: string;
  }) => {
    try {
      resetPassword.clearError();
      setWrongUserType(false);
      setUserSettingLoading(true);
      await apiManager.resetPassword({ ...values, setToken });
      toast(
        getSuccessToast({
          description: "Password successfully reset"
        })
      );
    } catch (err) {
      console.log(err);
      setUserSettingLoading(false);
      if (err === "NOT_ADMIN") {
        setWrongUserType(true);
        toast(
          getSuccessToast({
            description: "Password successfully reset"
          })
        );
      } else {
        throw err;
      }
    }
  };

  const resetPassword = useAsync<void>(
    resetPasswordExecution,
    "Error resetting password, please verify that your email is correct"
  );

  useLocalError([resetPassword.error]);

  return (
    <>
      <>
        {resetPassword.pending || userSettingLoading ? (
          <Loader isLoading />
        ) : (
          <Flex
            direction="column"
            w="30%"
            minW={300}
            margin="0 auto"
            mt="8"
            justifyContent="center"
            textAlign="center"
            bg="#FFF"
            p="24px"
          >
            <ScreenTitle text="Reset Password" />
            <Text color={theme.colors.darkBlue[500]} mb={4}>
              Check your email for your reset code.
            </Text>
            <FormikForm
              handleSubmit={resetPassword.execute}
              fields={resetPasswordFields}
              validationSchema={ResetPasswordSchema}
              topInstructionText="Password must contain: minimum of 8 characters, capital letter,
                          lowercase letter, number, & symbol (@$!%*#?&)"
            />
            {wrongUserType && (
              <Text color={"tomato"}>
                Please login to the mobile app with the link from your
                organization admin.
              </Text>
            )}
          </Flex>
        )}
      </>
    </>
  );
};

export default ResetPassword;
