var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import fetchWrapper from "./fetchWrapper";
import { nowInUnixEpochTime } from "../utils/dateTimeUtils";
import jwtDecode from "jwt-decode";
import { UserRole } from "../schemas/User";
export class ApiManager {
    constructor() {
        this.checkForDeactivation = (functionToCall, setToken) => __awaiter(this, void 0, void 0, function* () {
            try {
                return yield functionToCall();
            }
            catch (err) {
                if (err.name === "DEACTIVATED_USER" || err.name === "DEACTIVATED_ORG") {
                    yield this.refresh(setToken);
                }
                throw err;
            }
        });
        this.tokenIsValid = (token) => {
            try {
                return nowInUnixEpochTime() < jwtDecode(token).exp;
            }
            catch (error) {
                console.error(error);
                return false;
            }
        };
    }
    // Auth
    signIn(signinRequest, setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const body = (yield fetchWrapper({
                path: "auth/signin",
                method: "POST",
                body: signinRequest
            }));
            if (!body.userInOrgItems.find(userItem => userItem.userRole === UserRole.ADMIN)) {
                throw "NOT_ADMIN";
            }
            setToken(body.idToken);
            localStorage.setItem("mmsAccessToken", body.accessToken);
            localStorage.setItem("mmsRefreshToken", body.refreshToken);
        });
    }
    signOut(setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            setToken("");
        });
    }
    forgotPassword(body) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield fetchWrapper({ path: "auth/forgotpw", method: "POST", body });
        });
    }
    changePassword(body) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token, accessToken } = yield this.getRefreshedToken();
            return yield fetchWrapper({
                path: "auth/changepw",
                method: "POST",
                token,
                body: {
                    accessToken,
                    oldPassword: body.oldPassword,
                    newPassword: body.newPassword
                }
            });
        });
    }
    resetPassword({ email, newPassword, resetCode, setToken }) {
        return __awaiter(this, void 0, void 0, function* () {
            yield fetchWrapper({
                path: "auth/resetpw",
                method: "POST",
                body: {
                    email,
                    newPassword,
                    resetCode
                }
            });
            yield this.signIn({ email, password: newPassword }, setToken);
        });
    }
    // User
    getCurrentUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return (yield fetchWrapper({
                path: "user",
                token
            }));
        });
    }
    inviteUsers(userToInvite, setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return yield fetchWrapper({
                    path: "invitation",
                    method: "POST",
                    token,
                    body: userToInvite
                });
            }), setToken);
        });
    }
    resendInvite(email, organizationId, setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return yield fetchWrapper({
                    path: `invitation/resend`,
                    token,
                    method: "POST",
                    body: {
                        email,
                        organizationId
                    }
                });
            }), setToken);
        });
    }
    getUsers(organizationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return (yield fetchWrapper({
                path: `organization/${organizationId}/users`,
                token
            }));
        });
    }
    getInactiveUsers(organizationId, setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                var _a;
                return (_a = (yield fetchWrapper({
                    path: `organization/${organizationId}/users/deactivated`,
                    token
                }))) !== null && _a !== void 0 ? _a : [];
            }), setToken);
        });
    }
    validateSeatStatus(organizationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return (yield fetchWrapper({
                path: `organization/${organizationId}/user/seats`,
                token
            }));
        });
    }
    toggleUserRole({ email, organizationId, userRole, setToken }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return yield fetchWrapper({
                    path: `/organization/${organizationId}/user/role`,
                    method: "PATCH",
                    token,
                    body: {
                        email,
                        userRole
                    }
                });
            }), setToken);
        });
    }
    toggleUserActivation({ organizationId, status, email }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            yield fetchWrapper({
                path: `organization/${organizationId}/user/status`,
                method: "PATCH",
                token,
                body: {
                    email,
                    status
                }
            });
        });
    }
    uploadUserProfileImage({ file }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { token } = yield this.getRefreshedToken();
                const { presignedUrl } = yield this.getUploadUserPhotoPresignedUrl(file.type);
                const arrayBuffer = yield file.arrayBuffer();
                yield fetch(presignedUrl, {
                    headers: { "Content-Type": file.type },
                    method: "PUT",
                    body: arrayBuffer
                });
                return (yield fetchWrapper({
                    path: "user/photo/resize",
                    token
                }));
            }
            catch (err) {
                console.log(err);
                throw err;
            }
        });
    }
    updateCurrentUser(body) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            if (token) {
                return (yield fetchWrapper({
                    path: "user",
                    method: "PATCH",
                    body,
                    token
                }));
            }
            else {
                throw new Error("User needs to reauthenticate");
            }
        });
    }
    getUserPhotoPresignedUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { token } = yield this.getRefreshedToken();
                const { presignedUrl } = yield fetchWrapper({
                    path: `/user/photo`,
                    token
                });
                return presignedUrl;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    // Organization
    getOrganization(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return (yield fetchWrapper({
                path: `organization/${id}`,
                token
            }));
        });
    }
    getOrganizations() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return ((_a = (yield fetchWrapper({
                path: `organizations`,
                token
            }))) !== null && _a !== void 0 ? _a : []);
        });
    }
    createOrganization(body) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield fetchWrapper({
                path: `organization`,
                method: "POST",
                body
            }));
        });
    }
    updateOrganization(id, body, setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return (yield fetchWrapper({
                    path: `organization/${id}`,
                    token,
                    method: "PATCH",
                    body
                }));
            }), setToken);
        });
    }
    getUploadOrgLogoPresignedUrl({ fileType, orgId, setToken }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return yield fetchWrapper({
                    path: `/organization/${orgId}/logo`,
                    method: "POST",
                    body: { fileType },
                    token
                });
            }), setToken);
        });
    }
    deleteOrgLogo(orgId, setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return yield fetchWrapper({
                    path: `/organization/${orgId}/logo`,
                    method: "DELETE",
                    token
                });
            }), setToken);
        });
    }
    uploadOrgLogoImage({ file, id, setToken }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { presignedUrl } = yield this.getUploadOrgLogoPresignedUrl({
                    fileType: file.type,
                    orgId: id,
                    setToken
                });
                const arrayBuffer = yield file.arrayBuffer();
                yield fetch(presignedUrl, {
                    headers: { "Content-Type": file.type },
                    method: "PUT",
                    body: arrayBuffer
                });
                return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () { return yield this.resizeOrgLogo(id); }), setToken);
            }
            catch (err) {
                console.log(err);
                throw err;
            }
        });
    }
    resizeOrgLogo(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return (yield fetchWrapper({
                path: `/organization/${id}/logo/resize`,
                token
            }));
        });
    }
    getOrgLogoPresignedUrl(orgId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { token } = yield this.getRefreshedToken();
                const { presignedUrl } = yield fetchWrapper({
                    path: `/organization/${orgId}/logo`,
                    token
                });
                return presignedUrl;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    // Invitation
    signupViaInvitation(body, setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = (yield fetchWrapper({
                path: "invitation/signup",
                method: "POST",
                body
            }));
            setToken(res.idToken);
            localStorage.setItem("mmsAccessToken", res.accessToken);
            localStorage.setItem("mmsRefreshToken", res.refreshToken);
        });
    }
    validateInvitation(body) {
        return __awaiter(this, void 0, void 0, function* () {
            yield fetchWrapper({
                path: `invitation/validate`,
                method: "POST",
                body: {
                    email: body.email,
                    invitationCode: body.verificationCode
                }
            });
        });
    }
    getUploadUserPhotoPresignedUrl(fileType) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield fetchWrapper({
                path: `/user/photo`,
                method: "POST",
                body: { fileType },
                token
            });
        });
    }
    deleteUserPhoto() {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield fetchWrapper({
                path: `/user/photo`,
                method: "DELETE",
                token
            });
        });
    }
    // Events
    getUserEventsInOrg({ organizationId, email, lastKey, setToken }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            let queryString = "";
            if (lastKey) {
                queryString = `lastKey=${lastKey}`;
            }
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return (yield fetchWrapper({
                    path: `organization/${organizationId}/user/${email}/event` + queryString,
                    token
                }));
            }), setToken);
        });
    }
    getAllEventsInOrg(organizationId, selectedEvent, setToken, lastKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            let queryString = ``;
            if (lastKey) {
                queryString = `?lastKey=${lastKey}`;
            }
            if (selectedEvent) {
                const descriptionParam = selectedEvent === "Custom Description"
                    ? "custom"
                    : selectedEvent === "No Description"
                        ? "empty"
                        : selectedEvent === "Recalls"
                            ? "recall"
                            : selectedEvent;
                if (queryString) {
                    queryString += `&description=${descriptionParam}`;
                }
                else {
                    queryString = `?description=${descriptionParam}`;
                }
            }
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return (yield fetchWrapper({
                    path: `organization/${organizationId}/event` + queryString,
                    token
                }));
            }), setToken);
        });
    }
    getAllEventDescriptions(organizationId, setToken, all) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            if (all) {
                return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                    return (yield fetchWrapper({
                        path: `organization/${organizationId}/description?view=all`,
                        token
                    }));
                }), setToken);
            }
            else {
                return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                    return (yield fetchWrapper({
                        path: `organization/${organizationId}/description`,
                        token
                    }));
                }), setToken);
            }
        });
    }
    createEventDescription({ organizationId, description, setToken }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return (yield fetchWrapper({
                    path: `organization/${organizationId}/description`,
                    token,
                    method: "POST",
                    body: { description }
                }));
            }), setToken);
        });
    }
    updateEventDescription(organizationId, eventId, body, setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return (yield fetchWrapper({
                    path: `organization/${organizationId}/description/${eventId}`,
                    token,
                    body,
                    method: "PATCH"
                }));
            }), setToken);
        });
    }
    initiateRecall({ organizationId, description, location, resendInterval, setToken }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return (yield fetchWrapper({
                    path: `organization/${organizationId}/event/recall`,
                    token,
                    body: {
                        description,
                        location: location ? location : false,
                        resendInterval
                    },
                    method: "POST"
                }));
            }), setToken);
        });
    }
    isActiveRecall(organizationId, setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return (yield fetchWrapper({
                    path: `organization/${organizationId}/event/recall`,
                    token
                }));
            }), setToken);
        });
    }
    endRecall(eventId, organizationId, setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return (yield fetchWrapper({
                    path: `organization/${organizationId}/event/recall/${eventId}/end`,
                    token,
                    method: "PATCH"
                }));
            }), setToken);
        });
    }
    getRecallStatus(eventId, organizationId, setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return (yield fetchWrapper({
                    path: `organization/${organizationId}/event/recall/${eventId}`,
                    token
                }));
            }), setToken);
        });
    }
    resendRecall({ uid, eventCreatedAt, organizationId, emails, setToken }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            const eventKey = JSON.stringify({
                eventCreatedAt,
                uid
            });
            return yield this.checkForDeactivation(() => __awaiter(this, void 0, void 0, function* () {
                return yield fetchWrapper({
                    path: `organization/${organizationId}/event/recall/${eventKey}/resend`,
                    token,
                    method: "PATCH",
                    body: { emails }
                });
            }), setToken);
        });
    }
    // Stripe
    getPlanData(productId) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield fetchWrapper({
                path: `stripe/plan/${productId}`
            }));
        });
    }
    startBillingPortalSession(organizationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            return (yield fetchWrapper({
                path: `stripe/portal`,
                token,
                body: { organizationId },
                method: "POST"
            }));
        });
    }
    createCheckoutSession(body) {
        return __awaiter(this, void 0, void 0, function* () {
            const { token } = yield this.getRefreshedToken();
            const response = yield fetchWrapper({
                path: `stripe/checkout`,
                method: "POST",
                body,
                token
            });
            return response.headers.Location;
        });
    }
    viewAllPlans(orgType) {
        return __awaiter(this, void 0, void 0, function* () {
            if (orgType) {
                return (yield fetchWrapper({
                    path: `stripe/plans?orgType=${orgType}`
                }));
            }
            else {
                return (yield fetchWrapper({
                    path: `stripe/plans`
                }));
            }
        });
    }
    refresh(setToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const refreshToken = localStorage.getItem("mmsRefreshToken");
                const { idToken, accessToken } = yield fetchWrapper({
                    path: "auth/refresh",
                    method: "POST",
                    body: {
                        refreshToken
                    }
                });
                localStorage.setItem("mmsAccessToken", accessToken);
                if (setToken) {
                    setToken(idToken);
                }
                else {
                    localStorage.setItem("mmsToken", idToken);
                }
            }
            catch (err) {
                throw "Authentication error. Please log out and back in before trying again.";
            }
        });
    }
    getRefreshedToken() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let token = localStorage.getItem("mmsToken");
                let accessToken = localStorage.getItem("mmsAccessToken");
                if (token && this.tokenIsValid(token) && accessToken) {
                    return { token, accessToken };
                }
                else {
                    yield this.refresh();
                    token = localStorage.getItem("mmsToken");
                    accessToken = localStorage.getItem("mmsAccessToken");
                    if (token && accessToken) {
                        return { token, accessToken };
                    }
                    throw new Error("Tokens are invalid, login again");
                }
            }
            catch (err) {
                console.log(err);
                throw err;
            }
        });
    }
}
export const apiManager = new ApiManager();
