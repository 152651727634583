import React, { useContext, useEffect, useState } from "react";
import { RFC } from "../sharedTypes";
import { CardBackground } from "../components/ViewContainers";
import { ScreenTitle } from "../components/ScreenTitles";
import { Loader } from "../components/Loader";
import { Button } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { RouteNames } from "../navigation/routeNames";
import { navigateUserBasedOnRole } from "../navigation/navigationUtils";
import { UserContext } from "../context/UserContextProvider";

const Unauthorized: RFC = () => {
  const [authCheckDelay, setAuthCheckDelay] = useState<boolean>(true);
  const { currentUserInfo } = useContext(UserContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAuthCheckDelay(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  return authCheckDelay ? (
    <Loader isLoading={true} />
  ) : (
    <>
      <>
        <CardBackground>
          <ScreenTitle
            text="You are not authorized to view this page"
            centered
          />
          <Button
            colorScheme="darkBlue"
            onClick={() =>
              currentUserInfo
                ? navigateUserBasedOnRole(currentUserInfo)
                : navigate(RouteNames.root)
            }
          >
            Back to Safety
          </Button>
        </CardBackground>
      </>
    </>
  );
};

export default Unauthorized;
