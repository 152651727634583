import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const RegisterSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    password: Yup.string()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}/, "Invalid password; check requirements")
        .required("Required"),
    confirmPassword: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("password"), undefined], "Passwords must match"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phoneNumber: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Include area code")
        .required("Required")
});
export const registerFields = (initialEmailValue) => {
    return [
        {
            name: "email",
            label: "Email",
            placeholder: "Email",
            initialValue: initialEmailValue,
            disabled: true
        },
        {
            name: "password",
            label: "Password",
            placeholder: "Password",
            initialValue: "",
            password: true
        },
        {
            name: "confirmPassword",
            label: "Confirm Password",
            placeholder: "Confirm Password",
            initialValue: "",
            password: true
        },
        {
            name: "firstName",
            label: "First Name",
            placeholder: "First Name",
            initialValue: ""
        },
        {
            name: "lastName",
            label: "Last Name",
            placeholder: "Last Name",
            initialValue: ""
        },
        {
            name: "phoneNumber",
            label: "Phone Number",
            placeholder: "Phone Number",
            initialValue: ""
        }
    ];
};
