import React, { useContext, useEffect, useState } from "react";
import { CardBackground } from "../components/ViewContainers";
import { ScreenTitle } from "../components/ScreenTitles";
import {
  EventDescription,
  MMSEventWithData,
  ViewRecallStatusResponse
} from "../schemas/Event";
import { useAsync } from "../hooks/useAsync";
import { apiManager } from "../network/apiManager";
import { UserContext } from "../context/UserContextProvider";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { Loader } from "../components/Loader";
import { EditIcon } from "@chakra-ui/icons";
import theme from "../theme";
import { EventList } from "../components/EventList";
import { useDeactivated } from "../hooks/useDeactivated";
import { getSuccessToast } from "../components/Toasts";
import { RecallEventList } from "./RecallManagement";
import { format } from "date-fns";
import { useSetCurrentOrganization } from "../hooks/useSetCurrentOrganization";
import { useParams } from "@reach/router";

interface State {
  onInitialQuery: boolean;
  emptyResults: boolean;
  fullList: MMSEventWithData[];
  currentLastKey: string;
}

const initialState: State = {
  onInitialQuery: true,
  emptyResults: false,
  fullList: [],
  currentLastKey: ""
};

const SafeList = () => {
  const [state, setState] = useState<State>(initialState);
  const [selectedEvent, setSelectedEvent] = useState<string>("");
  const [eventEditingType, setEventEditingType] = useState<"existing" | "new">(
    "existing"
  );
  const [newEventDescription, setNewEventDescription] = useState<string>("");
  const [updatedEventDescription, setUpdatedEventDescription] = useState<
    string
  >("");
  const [selectedEventToEdit, setSelectedEventToEdit] = useState<string>("");
  const [selectedRecall, setSelectedRecall] = useState<string>("");
  const { onOpen, onClose, isOpen } = useDisclosure();
  const recallDetailsDrawer = useDisclosure();
  const {
    currentUserInfo,
    currentOrganization,
    setCurrentOrganization,
    setToken
  } = useContext(UserContext);
  useDeactivated(currentUserInfo, currentOrganization);
  const toast = useToast();
  const { id: orgIdInUrl } = useParams();
  useSetCurrentOrganization({
    currentUserInfo,
    currentOrganization,
    orgIdInUrl,
    setCurrentOrganization
  });
  const getSafeListAsync = async (): Promise<void> => {
    try {
      if (currentOrganization) {
        const results = await apiManager.getAllEventsInOrg(
          currentOrganization.organizationId,
          selectedEvent,
          setToken
        );
        setState(prevState => {
          return {
            ...prevState,
            currentLastKey: results.lastSortKey ?? "",
            fullList: results.events,
            onInitialQuery: false,
            emptyResults: !results.events.length
          };
        });
      } else {
        throw "Unauthorized";
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const getEventsAsync = async () => {
    try {
      if (currentOrganization) {
        return await apiManager.getAllEventDescriptions(
          currentOrganization.organizationId,
          setToken,
          false
        );
      } else {
        throw new Error("Unauthorized");
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const getAllEventsAsync = async () => {
    try {
      if (currentOrganization) {
        return await apiManager.getAllEventDescriptions(
          currentOrganization.organizationId,
          setToken,
          true
        );
      } else {
        throw new Error("Unauthorized");
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const getRecallDetailsAsync = async eventId => {
    try {
      if (currentOrganization) {
        return await apiManager.getRecallStatus(
          eventId,
          currentOrganization.organizationId,
          setToken
        );
      } else {
        throw new Error("Unauthorized");
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  useEffect(() => {
    if (currentOrganization) {
      getEvents.execute({});
      getAllEvents.execute({});
    }
  }, [currentOrganization]);

  const getSafeList = useAsync<void>(
    getSafeListAsync,
    "Error retrieving safe list."
  );
  const getEvents = useAsync<EventDescription[]>(
    getEventsAsync,
    "Error retrieving events."
  );

  const getAllEvents = useAsync<EventDescription[]>(
    getAllEventsAsync,
    "Error retrieving events."
  );

  const getRecallDetails = useAsync<ViewRecallStatusResponse>(
    getRecallDetailsAsync,
    "Error retrieving events."
  );

  useEffect(() => {
    if (currentOrganization) {
      getSafeList.execute({});
    }
  }, [selectedEvent, currentOrganization]);

  useEffect(() => {
    if (selectedRecall) {
      getRecallDetails.execute(selectedRecall);
      recallDetailsDrawer.onOpen();
    }
  }, [selectedRecall]);

  const createEventAsync = async () => {
    try {
      if (currentOrganization && newEventDescription) {
        await apiManager.createEventDescription({
          organizationId: currentOrganization.organizationId,
          description: newEventDescription,
          setToken
        });
        await getEvents.execute({});
        toast(
          getSuccessToast({
            title: "Successfully created description",
            description: ""
          })
        );
      } else {
        throw new Error("Unauthorized");
      }
      setNewEventDescription("");
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const updateEventAsync = async () => {
    try {
      if (currentOrganization && selectedEventToEdit) {
        await apiManager.updateEventDescription(
          currentOrganization.organizationId,
          selectedEventToEdit,
          { description: updatedEventDescription },
          setToken
        );
        await getEvents.execute({});
      } else {
        throw new Error("Unauthorized");
      }
      setSelectedEventToEdit("");
      setUpdatedEventDescription("");
    } catch (err) {
      console.log(JSON.stringify(err));
      throw err;
    }
  };

  const deleteEventAsync = async () => {
    try {
      if (currentOrganization && selectedEventToEdit) {
        await apiManager.updateEventDescription(
          currentOrganization.organizationId,
          selectedEventToEdit,
          { active: false },
          setToken
        );
        await getEvents.execute({});
      } else {
        throw new Error("Unauthorized");
      }
      setSelectedEventToEdit("");
      setUpdatedEventDescription("");
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const createEvent = useAsync<void>(
    createEventAsync,
    "Error creating event, please try again"
  );
  const updateEvent = useAsync<void>(
    updateEventAsync,
    "Error updating event, please try again"
  );
  const deleteEvent = useAsync<void>(
    deleteEventAsync,
    "Error deleting event, please try again"
  );

  const getMoreResults = async () => {
    try {
      if (currentOrganization) {
        const { fullList } = state;
        if (
          !state.emptyResults &&
          !state.onInitialQuery &&
          fullList?.length &&
          state.currentLastKey
        ) {
          const results = await apiManager.getAllEventsInOrg(
            currentOrganization.organizationId,
            selectedEvent,
            setToken,
            state.currentLastKey
          );
          if (!results.events.length) {
            setState(prevState => {
              return { ...prevState, emptyResults: true, currentLastKey: "" };
            });
          }
          setState(prevState => {
            return {
              ...prevState,
              fullList: [...prevState.fullList, ...results.events],
              currentLastKey: results.lastSortKey ?? ""
            };
          });
        }
      }
    } catch (err) {
      console.log("error in get more results", err);
      throw err;
    }
  };

  return (
    <CardBackground>
      <ScreenTitle text="Safe List" centered />
      <Flex justify={"flex-end"}>
        <Button
          colorScheme="darkBlue"
          w={"250px"}
          leftIcon={<EditIcon />}
          onClick={onOpen}
          mb={2}
        >
          Manage Saved Descriptions
        </Button>
      </Flex>
      <Tabs w="100%" variant="unstyled">
        <TabList>
          <Tab
            _selected={{ color: "darkBlue.500", bg: "lime.500" }}
            maxW={"23%"}
            onClick={() => setSelectedEvent("all")}
          >
            All Events
          </Tab>
          <Tab
            _selected={{ color: "darkBlue.500", bg: "lime.500" }}
            maxW={"23%"}
            onClick={() =>
              setState(prevState => ({
                ...prevState,
                fullList: [],
                currentLastKey: ""
              }))
            }
          >
            Saved Description
          </Tab>
          <Tab
            _selected={{ color: "darkBlue.500", bg: "lime.500" }}
            maxW={"23%"}
            onClick={() => setSelectedEvent("custom")}
          >
            Custom Description
          </Tab>
          <Tab
            _selected={{ color: "darkBlue.500", bg: "lime.500" }}
            maxW={"23%"}
            onClick={() => setSelectedEvent("empty")}
          >
            No Description
          </Tab>
          <Tab
            _selected={{ color: "darkBlue.500", bg: "lime.500" }}
            maxW={"23%"}
            onClick={() => setSelectedEvent("recall")}
          >
            Recalls
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {getSafeList.pending ? (
              <Loader isLoading={getSafeList.pending} />
            ) : getSafeList.error ? (
              <Text color="tomato">Error getting events</Text>
            ) : (
              <EventList
                events={state.fullList}
                onLoadMore={getMoreResults}
                moreEvents={!!state.currentLastKey}
                onClick={eventId => setSelectedRecall(eventId)}
              />
            )}
          </TabPanel>
          <TabPanel>
            <FormLabel>Select description</FormLabel>
            {getAllEvents.value ? (
              <Select
                onChange={e => setSelectedEvent(e.target.value)}
                value={selectedEvent}
                placeholder={"Select a description"}
              >
                {getAllEvents.value.map(item => (
                  <option value={item.descriptionId} key={item.descriptionId}>
                    {item.description}
                  </option>
                ))}
              </Select>
            ) : null}
            {getSafeList.pending || getAllEvents.pending ? (
              <Loader isLoading={getSafeList.pending || getAllEvents.pending} />
            ) : getEvents.error ? (
              <Text color="tomato">Error getting events</Text>
            ) : (
              <EventList
                events={state.fullList}
                onLoadMore={getMoreResults}
                moreEvents={!!state.currentLastKey}
                onClick={eventId => setSelectedRecall(eventId)}
              />
            )}
          </TabPanel>
          <TabPanel>
            {getSafeList.pending ? (
              <Loader isLoading={getSafeList.pending} />
            ) : getSafeList.error ? (
              <Text color="tomato">Error getting events</Text>
            ) : (
              <EventList
                events={state.fullList}
                onLoadMore={getMoreResults}
                moreEvents={!!state.currentLastKey}
                onClick={eventId => setSelectedRecall(eventId)}
              />
            )}{" "}
          </TabPanel>
          <TabPanel>
            {getSafeList.pending ? (
              <Loader isLoading={getSafeList.pending} />
            ) : getSafeList.error ? (
              <Text color="tomato">Error getting events</Text>
            ) : (
              <EventList
                events={state.fullList}
                onLoadMore={getMoreResults}
                moreEvents={!!state.currentLastKey}
                onClick={eventId => setSelectedRecall(eventId)}
              />
            )}{" "}
          </TabPanel>
          <TabPanel>
            {getSafeList.pending ? (
              <Loader isLoading={getSafeList.pending} />
            ) : getSafeList.error ? (
              <Text color="tomato">Error getting events</Text>
            ) : (
              <EventList
                events={state.fullList}
                onLoadMore={getMoreResults}
                moreEvents={!!state.currentLastKey}
                onClick={eventId => setSelectedRecall(eventId)}
              />
            )}{" "}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Drawer
        isOpen={recallDetailsDrawer.isOpen}
        placement="right"
        onClose={recallDetailsDrawer.onClose}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton w={"40px"} focusBorderColor={"lime"} />
          <DrawerHeader color={theme.colors.darkBlue[500]}>
            Recall Details
          </DrawerHeader>
          <DrawerBody>
            {getRecallDetails.pending ? (
              <Loader isLoading={getRecallDetails.pending} />
            ) : (
              <>
                <Text mb={4}>
                  <strong>Reason for Recall:</strong>{" "}
                  {getRecallDetails.value?.summary.description}
                </Text>
                {getRecallDetails.value?.summary.eventCreatedAt && (
                  <Text mb={4}>
                    <strong>Started At:</strong>{" "}
                    {format(
                      new Date(getRecallDetails.value.summary.eventCreatedAt),
                      "MM-dd-yy," + " hh:mm a"
                    )}
                  </Text>
                )}
                {getRecallDetails.value?.summary?.markedSafeAt !== "false" &&
                  getRecallDetails.value?.summary?.markedSafeAt && (
                    <Text mb={4}>
                      <strong>Ended At:</strong>{" "}
                      {format(
                        new Date(getRecallDetails.value.summary.markedSafeAt),
                        "MM-dd-yy," + " hh:mm a"
                      )}
                    </Text>
                  )}
                <Text>
                  <strong>Marked Safe:</strong>{" "}
                  {getRecallDetails.value?.totalSafe}/
                  {getRecallDetails.value?.totalMembers}
                </Text>
                <Text
                  color={theme.colors.darkBlue[500]}
                  fontWeight={"bold"}
                  mt={4}
                >
                  Details by User
                </Text>
                <Box p={2} borderColor={"gray"} borderWidth={1}>
                  {getRecallDetails.value && (
                    <RecallEventList
                      events={getRecallDetails.value.responses}
                    />
                  )}
                </Box>
              </>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button
              variant="outline"
              mr={3}
              onClick={recallDetailsDrawer.onClose}
            >
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent w={["90%", "80%", "70%", "60%"]}>
          <ModalHeader color={theme.colors.darkBlue[500]}>
            Manage Saved Descriptions
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup
              onChange={(val: "existing" | "new") => setEventEditingType(val)}
              value={eventEditingType}
              mb={2}
            >
              <Stack direction="row">
                <Radio value="existing">Edit Existing</Radio>
                <Radio value="new">Create New</Radio>
              </Stack>
            </RadioGroup>
            {eventEditingType === "existing" ? (
              getEvents.value ? (
                <>
                  <FormLabel>Edit or delete description</FormLabel>
                  <Select
                    onChange={e => {
                      setSelectedEventToEdit(e.target.value);
                      setUpdatedEventDescription(
                        getEvents.value?.find(
                          item => item.descriptionId === e.target.value
                        )?.description ?? ""
                      );
                    }}
                    value={selectedEventToEdit}
                    isDisabled={!getEvents.value?.length}
                    placeholder={"Choose a description"}
                  >
                    {getEvents.value.map(item => (
                      <option
                        value={item.descriptionId}
                        key={item.descriptionId}
                      >
                        {item.description}
                      </option>
                    ))}
                  </Select>
                  <Button
                    colorScheme="orange"
                    mt={2}
                    onClick={() => deleteEvent.execute({})}
                    isDisabled={!selectedEventToEdit}
                  >
                    Delete Description
                  </Button>
                  <FormLabel>Edit selected description</FormLabel>
                  <Input
                    value={updatedEventDescription}
                    onChange={e => setUpdatedEventDescription(e.target.value)}
                    isDisabled={!selectedEventToEdit}
                    borderColor={"#F1F1F1"}
                  />

                  <Button
                    colorScheme="orange"
                    mt={2}
                    onClick={() => updateEvent.execute({})}
                    isDisabled={
                      !selectedEventToEdit || !updatedEventDescription
                    }
                  >
                    Save Changes
                  </Button>
                </>
              ) : (
                <Text>No events to edit</Text>
              )
            ) : (
              <>
                {createEvent.pending ? (
                  <Loader isLoading={createEvent.pending} />
                ) : (
                  <>
                    <FormLabel mt={2}>New event description</FormLabel>
                    <Input
                      value={newEventDescription}
                      onChange={e => setNewEventDescription(e.target.value)}
                    />
                    <Button
                      colorScheme="orange"
                      mt={2}
                      onClick={() => createEvent.execute({})}
                    >
                      Save New Description
                    </Button>
                  </>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="darkBlue"
              onClick={() => {
                getAllEvents.execute();
                onClose();
              }}
            >
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </CardBackground>
  );
};

export default SafeList;
