import React, { ReactNode } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import theme from "../theme";

interface Props {
  handleSubmit: (values?: any) => any;
  bodyText: string | ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
}

export function ConfirmationModal({
  handleSubmit,
  title = "Are you sure?",
  bodyText,
  submitButtonLabel = "Yes",
  cancelButtonLabel = "No",
  isOpen,
  onClose
}: Props) {
  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent w={["90%", "80%", "70%", "60%"]}>
        <ModalHeader color={theme.colors.darkBlue[500]}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{bodyText}</ModalBody>
        <ModalFooter>
          <Button colorScheme="darkGrey" mr={4} onClick={onClose}>
            {cancelButtonLabel}
          </Button>
          <Button colorScheme="darkBlue" onClick={handleSubmit}>
            {submitButtonLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
