import React, { createContext, ReactNode, useEffect, useState } from "react";
import { apiManager } from "../network/apiManager";
import { CurrentUserResponse, UserRole, UserStatus } from "../schemas/User";
import { ViewOrganizationResponse } from "../schemas/Organization";

type UserContextType = {
  token: string;
  setToken: (newToken: string) => void;
  wrongUserType: boolean;
  setWrongUserType: (newWrongUserType: boolean) => void;
  currentUserInfo: CurrentUserResponse | null | undefined;
  setCurrentUserInfo: (user: CurrentUserResponse | null) => void;
  currentOrganization: ViewOrganizationResponse | null | undefined;
  setCurrentOrganization: (
    organization: ViewOrganizationResponse | null
  ) => void;
  userLoading: boolean;
  currentOrgLogoPresignedUrl: string;
  setCurrentOrgLogoPresignedUrl: (newUrl: string) => void;
  currentUserPhotoPresignedUrl: string;
  setCurrentUserPhotoPresignedUrl: (newUrl: string) => void;
};

export const UserContext = createContext<UserContextType>({
  token: "",
  setToken: () => {},
  wrongUserType: false,
  setWrongUserType: () => {},
  currentUserInfo: null,
  setCurrentUserInfo: () => {},
  currentOrganization: null,
  setCurrentOrganization: () => {},
  userLoading: false,
  currentOrgLogoPresignedUrl: "",
  setCurrentOrgLogoPresignedUrl: () => {},
  currentUserPhotoPresignedUrl: "",
  setCurrentUserPhotoPresignedUrl: () => {}
});

export default function UserContextProvider({
  children
}: {
  children: ReactNode;
}) {
  const [
    currentUserInfo,
    setCurrentUserInfo
  ] = useState<CurrentUserResponse | null>(null);
  const [
    currentOrganization,
    setCurrentOrganization
  ] = useState<ViewOrganizationResponse | null>(null);
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [wrongUserType, setWrongUserType] = useState<boolean>(false);
  const [currentOrgLogoPresignedUrl, setCurrentOrgLogoPresignedUrl] = useState<
    string
  >("");
  const [
    currentUserPhotoPresignedUrl,
    setCurrentUserPhotoPresignedUrl
  ] = useState<string>("");
  const [token, setToken] = useState<string>(
    localStorage.getItem("mmsToken") ?? ""
  );

  useEffect(() => {
    (async () => {
      try {
        setWrongUserType(false);
        // valid token
        if (token) {
          setUserLoading(true);
          localStorage.setItem("mmsToken", token);
          const user = await apiManager.getCurrentUser();
          // if user is only a member
          if (
            !user.userInOrgItems.find(
              userItem => userItem.userRole === UserRole.ADMIN
            )
          ) {
            localStorage.removeItem("mmsToken");
            localStorage.removeItem("mmsAccessToken");
            localStorage.removeItem("mmsRefreshToken");
            setWrongUserType(true);
            setUserLoading(false);
          } else {
            setCurrentUserInfo(user);

            // Then set the user photo
            if (
              user?.currentUser.profilePic &&
              user.currentUser.status !== UserStatus.DEACTIVATED
            ) {
              const userPhotoUrl = await apiManager.getUserPhotoPresignedUrl();
              setCurrentUserPhotoPresignedUrl(userPhotoUrl);
            }
            setUserLoading(false);

            // invalid token, invalid refresh token, or no tokens
          }
        } else if (!token) {
          setCurrentUserInfo(null);
          setCurrentOrganization(null);
          setUserLoading(false);
          setCurrentUserPhotoPresignedUrl("");
          setCurrentOrgLogoPresignedUrl("");
          localStorage.removeItem("mmsToken");
          localStorage.removeItem("mmsAccessToken");
          localStorage.removeItem("mmsRefreshToken");
        }
      } catch (error) {
        console.log(error);
        setCurrentUserInfo(null);
        setCurrentOrganization(null);
        setUserLoading(false);
        setCurrentOrgLogoPresignedUrl("");
        setCurrentUserPhotoPresignedUrl("");
      }
    })();
  }, [token]);

  // when we set an organization, get its logo
  useEffect(() => {
    (async () => {
      if (currentOrganization) {
        if (currentOrganization?.logoPic) {
          const orgLogoUrl = await apiManager.getOrgLogoPresignedUrl(
            currentOrganization.organizationId
          );
          setCurrentOrgLogoPresignedUrl(orgLogoUrl);
        } else {
          setCurrentOrgLogoPresignedUrl("");
        }
      }
    })();
  }, [currentOrganization]);

  return (
    <UserContext.Provider
      value={{
        token,
        setToken,
        currentUserInfo,
        setCurrentUserInfo,
        wrongUserType,
        setWrongUserType,
        userLoading,
        currentOrganization,
        setCurrentOrganization,
        currentOrgLogoPresignedUrl,
        setCurrentOrgLogoPresignedUrl,
        currentUserPhotoPresignedUrl,
        setCurrentUserPhotoPresignedUrl
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
