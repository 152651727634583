import React, { useContext, useEffect } from "react";
import { Text, useToast } from "@chakra-ui/react";
import useURLParams from "../hooks/useURLParams";
import { apiManager } from "../network/apiManager";
import { RFC } from "../sharedTypes";
import { UserContext } from "../context/UserContextProvider";
import { useAsync } from "../hooks/useAsync";
import { CardBackground } from "../components/ViewContainers";
import {
  registerFields,
  RegisterSchema
} from "../forms/schemas/registerSchema";
import { ScreenTitle } from "../components/ScreenTitles";
import { Loader } from "../components/Loader";
import { FormikForm } from "../components/FormikForm";
import { navigateUserBasedOnRole } from "../navigation/navigationUtils";

const Register: RFC = () => {
  const toast = useToast();
  let [email] = useURLParams("email");
  email = decodeURIComponent(email);
  let [verificationCode] = useURLParams("verificationCode");
  verificationCode = decodeURIComponent(verificationCode);
  const { setToken, currentUserInfo, wrongUserType } = useContext(UserContext);
  const validateInvitationAsync = async () => {
    try {
      return await apiManager.validateInvitation({
        email,
        verificationCode
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const signupViaInvitationAsync = async values => {
    try {
      const body = {
        ...values,
        phoneNumber: parseInt(values.phoneNumber),
        newPassword: values.password,
        invitationCode: verificationCode
      };
      delete body.password;
      delete body.confirmPassword;
      signupViaInvitation.clearError();
      await apiManager.signupViaInvitation(body, setToken);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const signupViaInvitation = useAsync<void>(
    signupViaInvitationAsync,
    "Error signing up; please verify your email address and check your connection."
  );

  const validateInvitation = useAsync<void>(
    validateInvitationAsync,
    "Invalid invitation; please request a new one from your administrator."
  );

  useEffect(() => {
    return () => {
      validateInvitation.clearError();
      signupViaInvitation.clearError();
      toast.closeAll();
    };
  }, []);

  useEffect(() => {
    if (!currentUserInfo) {
      validateInvitation.execute();
    } else {
      navigateUserBasedOnRole(currentUserInfo);
    }
  }, [currentUserInfo]);

  return (
    <>
      {signupViaInvitation.pending || validateInvitation.pending ? (
        <Loader
          isLoading={signupViaInvitation.pending && validateInvitation.pending}
        />
      ) : (
        <CardBackground>
          <ScreenTitle text="Register" centered />
          {!!validateInvitation.error ? (
            <Text color="tomato">{validateInvitation.error}</Text>
          ) : (
            <>
              <FormikForm
                handleSubmit={signupViaInvitation.execute}
                fields={registerFields(email)}
                validationSchema={RegisterSchema}
                topInstructionText="Password must contain: minimum of 8 characters, capital letter,
                          lowercase letter, number, & symbol (@$!%*#?&)"
              />
              {wrongUserType && (
                <Text color={"tomato"}>
                  Successful registration! But only admin accounts can use the
                  web portal. Please download the mobile app with the link from
                  your organization admin.
                </Text>
              )}
            </>
          )}
        </CardBackground>
      )}
    </>
  );
};

export default Register;
