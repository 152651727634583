export var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "ADMIN";
    UserRole["MEMBER"] = "MEMBER";
    UserRole["OWNER"] = "OWNER";
})(UserRole || (UserRole = {}));
export var UserStatus;
(function (UserStatus) {
    // note: any future new options must also be added to `UserConfirmed` type
    UserStatus["ACTIVE"] = "ACTIVE";
    UserStatus["PENDING"] = "PENDING";
    UserStatus["DEACTIVATED"] = "DEACTIVATED";
})(UserStatus || (UserStatus = {}));
export const isUserPending = (user) => {
    return user.status === UserStatus.PENDING;
};
var StripePlanUnitPricingType;
(function (StripePlanUnitPricingType) {
    StripePlanUnitPricingType["group"] = "group";
    StripePlanUnitPricingType["seat"] = "seat";
})(StripePlanUnitPricingType || (StripePlanUnitPricingType = {}));
