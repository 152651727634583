import React, { Suspense, useContext, useEffect, useState } from "react";
import { RouteNames } from "./navigation/routeNames";
import { CSSReset } from "@chakra-ui/react";
import OrganizationList from "./pages/OrganizationList";
import Profile from "./pages/Profile";
import OrganizationUserList from "./pages/OrganizationDetails";
import UserContextProvider, {
  UserContext
} from "./context/UserContextProvider";
import { Loader } from "./components/Loader";
import { apiManager } from "./network/apiManager";
import { NavBarRenderer } from "./components/NavbarRenderer";
import { Router } from "@reach/router";
import Login from "./pages/Login";
import InitialRegister from "./pages/InitialRegister";
import ResetPassword from "./pages/ResetPassword";
import { AuthRoute, AuthRouteWithParams } from "./navigation/AuthRoute";
import Unauthorized from "./pages/Unauthorized";
import NotFound from "./pages/NotFound";
import { ViewContainer } from "./components/ViewContainers";
import { SuspenseImg } from "./components/SuspenseImage";
import { useCurrentWidth } from "./hooks/useCurrentWidth";
import SafeList from "./pages/SafeList";
import RecallManagement from "./pages/RecallManagement";
import RegisterByPlan from "./pages/RegisterByPlan";
import Register from "./pages/Register";
import { OrgSelectRedirect } from "./pages/OrgSelectRedirect";

const App = () => {
  const { userLoading, setToken } = useContext(UserContext);
  const [isReady, setIsReady] = useState<boolean>(false);
  const width = useCurrentWidth();

  useEffect(() => {
    (async () => {
      try {
        if (localStorage.getItem("mmsRefreshToken")) {
          await apiManager.refresh(setToken);
          setIsReady(true);
        } else {
          setIsReady(true);
        }
      } catch (err) {
        setIsReady(true);
      }
    })();
  }, []);

  return (
    <UserContextProvider>
      {userLoading || !isReady ? (
        <Loader isLoading />
      ) : (
        <>
          <CSSReset />
          <Suspense fallback={<Loader isLoading />}>
            <NavBarRenderer />
            <ViewContainer
              width={width}
              loadedImage={
                <SuspenseImg src={require("./assets/top-photo.jpg")} />
              }
            >
              <Router>
                <Login path={RouteNames.root} />
                <Register path={RouteNames.register} />
                <InitialRegister path={RouteNames.initialRegister} />
                <RegisterByPlan path={RouteNames.registerForPlan} />
                <ResetPassword path={RouteNames.resetPassword} />
                <AuthRoute
                  component={OrganizationList}
                  path={RouteNames.organizations}
                />
                <AuthRoute
                  component={OrgSelectRedirect}
                  path={RouteNames.recall}
                  //@ts-ignore
                  redirect={RouteNames.recall}
                />
                <AuthRoute
                  component={OrgSelectRedirect}
                  path={RouteNames.safeList}
                  //@ts-ignore
                  redirect={RouteNames.safeList}
                />
                <AuthRoute
                  component={OrgSelectRedirect}
                  path={RouteNames.users}
                  //@ts-ignore
                  redirect={RouteNames.users}
                />
                <AuthRouteWithParams
                  component={OrganizationUserList}
                  path={RouteNames.users + "/:id"}
                />
                <AuthRouteWithParams
                  component={SafeList}
                  path={RouteNames.safeList + "/:id"}
                />
                <AuthRouteWithParams
                  component={RecallManagement}
                  path={RouteNames.recall + "/:id"}
                />
                <AuthRoute component={Profile} path={RouteNames.profile} />
                <Unauthorized path={RouteNames.unauthorized} />
                <NotFound default />
              </Router>
            </ViewContainer>
          </Suspense>
        </>
      )}
    </UserContextProvider>
  );
};

export default App;
