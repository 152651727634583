import React, { useContext, useEffect, useMemo, useState } from "react";
import { CardBackground } from "../components/ViewContainers";
import { ScreenTitle } from "../components/ScreenTitles";
import { apiManager } from "../network/apiManager";
import { UserContext } from "../context/UserContextProvider";
import { useAsync } from "../hooks/useAsync";
import {
  MMSEvent,
  MMSEventWithData,
  ViewRecallStatusResponse
} from "../schemas/Event";
import { Loader } from "../components/Loader";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {
  CheckIcon,
  CloseIcon,
  ExternalLinkIcon,
  RepeatIcon
} from "@chakra-ui/icons";
import { format } from "date-fns";
import theme from "../theme";
import { getSuccessToast } from "../components/Toasts";
import { useDeactivated } from "../hooks/useDeactivated";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { useSetCurrentOrganization } from "../hooks/useSetCurrentOrganization";
import { useParams } from "@reach/router";

type RecallFilter = "all" | "responded" | "not_responded";

const RecallManagement = () => {
  const {
    currentOrganization,
    currentUserInfo,
    setCurrentOrganization,
    setToken
  } = useContext(UserContext);
  const confirmEnd = useDisclosure();
  const toast = useToast();
  const [activeRecallEvent, setActiveRecallEvent] = useState<MMSEvent | null>(
    null
  );
  const [newRecallDescription, setNewRecallDescription] = useState<string>("");
  const [isAutomatingResending, setIsAutomatingResending] = useState<boolean>(
    true
  );
  const { id: orgIdInUrl } = useParams();
  const [resendInterval, setResendInterval] = useState<number>(5);
  const [currentFilter, setCurrentFilter] = useState<RecallFilter>("all");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  useDeactivated(currentUserInfo, currentOrganization);
  useSetCurrentOrganization({
    currentUserInfo,
    currentOrganization,
    orgIdInUrl,
    setCurrentOrganization
  });
  useEffect(() => {
    getIsActiveRecall.execute({});
  }, [currentOrganization]);

  const getIsActiveRecallAsync = async () => {
    if (currentOrganization) {
      const activeRecall = await apiManager.isActiveRecall(
        currentOrganization.organizationId,
        setToken
      );
      if (activeRecall.active) {
        setActiveRecallEvent(activeRecall.event);
      } else {
        setActiveRecallEvent(null);
      }
    }
  };

  const initiateRecallAsync = async () => {
    try {
      initiateRecall.clearError();
      if (currentOrganization) {
        await apiManager.initiateRecall({
          organizationId: currentOrganization.organizationId,
          description: newRecallDescription,
          location: false,
          resendInterval: isAutomatingResending ? resendInterval : 0,
          setToken
        });
        setNewRecallDescription("");
        getIsActiveRecall.execute({});
      } else {
        throw "Unauthorized";
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const resendRecallAsync = async () => {
    try {
      if (selectedUsers.length && currentOrganization && activeRecallEvent) {
        await apiManager.resendRecall({
          uid: activeRecallEvent.eventId,
          eventCreatedAt: activeRecallEvent.eventCreatedAt,
          organizationId: currentOrganization.organizationId,
          emails: selectedUsers,
          setToken
        });
        toast(
          getSuccessToast({
            title: "Success",
            description: `Recall notification sent to selected users`
          })
        );
        setSelectedUsers([]);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const endRecallAsync = async () => {
    try {
      endRecall.clearError();
      setActiveRecallEvent(null);
      if (activeRecallEvent && currentOrganization) {
        return await apiManager.endRecall(
          activeRecallEvent.eventId,
          currentOrganization.organizationId,
          setToken
        );
      } else {
        throw new Error("No active recall");
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  useEffect(() => {
    getStatus.execute({});
  }, [activeRecallEvent]);

  const getEventDetailsAsync = async () => {
    try {
      if (currentOrganization && activeRecallEvent?.eventId) {
        return apiManager.getRecallStatus(
          activeRecallEvent.eventId,
          currentOrganization.organizationId,
          setToken
        );
      } else {
        throw "Unauthorized";
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const onChangeCheckbox = (email: string) => {
    if (selectedUsers.includes(email)) {
      const updatedUsers = selectedUsers.filter(item => item !== email);
      setSelectedUsers(updatedUsers);
    } else {
      setSelectedUsers(prevState => [...prevState, email]);
    }
  };

  const selectAllUsers = () => {
    if (getStatus.value) {
      const onlyNotRespondedEmails = getStatus.value.responses
        .filter(response => filterResponses(response, "not_responded"))
        .map(response => response.email);
      setSelectedUsers(onlyNotRespondedEmails);
    }
  };

  const getIsActiveRecall = useAsync<void>(
    getIsActiveRecallAsync,
    "Error checking for active recall status"
  );
  const initiateRecall = useAsync<void>(
    initiateRecallAsync,
    "Error initiating recall."
  );
  const resendRecall = useAsync<void>(
    resendRecallAsync,
    "Error resending recall."
  );
  const endRecall = useAsync<MMSEvent>(endRecallAsync, "Error ending recall.");
  const getStatus = useAsync<ViewRecallStatusResponse>(getEventDetailsAsync);

  const showDeselect = useMemo(() => {
    const onlyNotRespondedEmails =
      getStatus.value?.responses
        .filter(response => filterResponses(response, "not_responded"))
        .map(response => response.email) ?? [];
    return selectedUsers.length === onlyNotRespondedEmails.length;
  }, [selectedUsers, getStatus.value]);

  return (
    <CardBackground>
      <ScreenTitle text="Recalls" centered />
      {!!getIsActiveRecall.error && (
        <Text color="tomato">{getIsActiveRecall.error}</Text>
      )}
      {getIsActiveRecall.pending ? (
        <Loader isLoading={getIsActiveRecall.pending} />
      ) : !activeRecallEvent ? (
        <>
          <Text fontWeight={"bold"} fontSize={"lg"}>
            No Recall In Progress
          </Text>
          <Text fontWeight={"bold"} textAlign={"left"}>
            Start one now?
          </Text>
          <FormLabel>Recall Description</FormLabel>
          <Input
            value={newRecallDescription}
            onChange={e => setNewRecallDescription(e.target.value)}
          />
          <Flex align={"center"}>
            <FormLabel>Automatic Resending?</FormLabel>
            <Switch
              isChecked={isAutomatingResending}
              onChange={() => setIsAutomatingResending(prevState => !prevState)}
            />
          </Flex>
          <FormLabel>Resend Interval (min)</FormLabel>
          <NumberInput
            defaultValue={5}
            min={1}
            value={resendInterval}
            onChange={val => setResendInterval(parseInt(val))}
            isDisabled={!isAutomatingResending}
            mb={4}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Button
            colorScheme={"orange"}
            isDisabled={!newRecallDescription}
            onClick={initiateRecall.execute}
          >
            Start Recall
          </Button>
        </>
      ) : (
        <>
          <Flex align={"center"} justify={"space-between"}>
            <Text
              fontWeight={"bold"}
              fontSize={"lg"}
              mb={4}
              textAlign={"center"}
            >
              Recall In Progress
            </Text>
            <Button
              colorScheme="darkBlue"
              w={"40px"}
              onClick={() => {
                getIsActiveRecall.execute();
                getStatus.execute();
              }}
              mb={2}
            >
              <RepeatIcon />
            </Button>
          </Flex>
          {getStatus.pending ? (
            <Loader isLoading={getStatus.pending} />
          ) : !!getStatus.error ? (
            <Text color="tomato">
              Error getting status, please check your connection
            </Text>
          ) : (
            <>
              <Flex justify={"space-between"}>
                <Box textAlign={"left"}>
                  <Text mb={4}>
                    <strong>Reason for Recall:</strong>{" "}
                    {getStatus.value?.summary.description}
                  </Text>
                  {getStatus.value && (
                    <Text mb={4}>
                      <strong>Started At:</strong>{" "}
                      {format(
                        new Date(getStatus.value.summary.eventCreatedAt),
                        "MM-dd-yy," + " hh:mm a"
                      )}
                    </Text>
                  )}
                  <Text>
                    <strong>Marked Safe:</strong> {getStatus.value?.totalSafe}/
                    {getStatus.value?.totalMembers}
                  </Text>
                </Box>
                <Stack>
                  <Button
                    colorScheme={"orange"}
                    leftIcon={<RepeatIcon />}
                    onClick={resendRecall.execute}
                    isDisabled={!selectedUsers.length}
                  >
                    Resend to {selectedUsers.length} Member(s)
                  </Button>
                  <Button
                    colorScheme={"darkBlue"}
                    leftIcon={<CloseIcon />}
                    onClick={confirmEnd.onOpen}
                  >
                    End Recall
                  </Button>
                </Stack>
              </Flex>
              <Flex justify={"space-between"} align={"flex-end"}>
                <Button
                  maxW={"200px"}
                  isDisabled={currentFilter === "responded"}
                  onClick={() =>
                    showDeselect ? setSelectedUsers([]) : selectAllUsers()
                  }
                >
                  {showDeselect ? "Clear all" : "Select All"}
                </Button>
                <RadioGroup
                  onChange={val => setCurrentFilter(val as RecallFilter)}
                  value={currentFilter}
                  my={4}
                >
                  <Stack>
                    <Radio value="all" mr={3}>
                      All
                    </Radio>
                    <Radio value="responded" mr={3}>
                      Responded
                    </Radio>
                    <Radio value="not_responded" mr={3}>
                      Not Responded
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
            </>
          )}
          <RecallEventList
            events={
              getStatus.value?.responses.filter(response =>
                filterResponses(response, currentFilter)
              ) ?? []
            }
            onChangeCheckbox={onChangeCheckbox}
            selectedUsers={selectedUsers}
          />
        </>
      )}
      <ConfirmationModal
        handleSubmit={() => {
          endRecall.execute({});
          confirmEnd.onClose();
        }}
        bodyText={"Are you sure you want to end this recall?"}
        isOpen={confirmEnd.isOpen}
        onClose={confirmEnd.onClose}
      />
    </CardBackground>
  );
};

const filterResponses = (response: MMSEventWithData, filter: RecallFilter) => {
  if (filter === "not_responded") {
    return response.markedSafeAt === "false";
  }
  if (filter === "responded") {
    return response.markedSafeAt !== "false";
  }
  return true;
};

export const RecallEventList = ({
  events,
  onChangeCheckbox,
  selectedUsers
}: {
  events: Array<MMSEventWithData>;
  onChangeCheckbox?: (email: string) => void;
  selectedUsers?: string[];
}) => (
  <>
    {events.map(event =>
      onChangeCheckbox && selectedUsers ? (
        <RecallEventRow
          event={event}
          key={event.uid}
          onChangeCheckbox={onChangeCheckbox}
          isChecked={!!selectedUsers.find(email => email === event.email)}
        />
      ) : (
        <RecallEventRow key={event.uid} event={event} isChecked={false} />
      )
    )}
  </>
);

const RecallEventRow = ({
  event,
  onChangeCheckbox,
  isChecked
}: {
  event: MMSEventWithData;
  onChangeCheckbox?: (email: string) => void;
  isChecked: boolean;
}) => {
  return (
    <Box
      px={4}
      py={2}
      borderBottomWidth="1px"
      _last={{ borderBottomWidth: 0 }}
      w="100%"
      textAlign="left"
      h="75px"
    >
      <Flex alignItems="center">
        {event.markedSafeAt === "false" && onChangeCheckbox && (
          <Checkbox
            mr={4}
            size={"lg"}
            borderColor={theme.colors.darkBlue["500"]}
            isChecked={isChecked}
            onChange={() => onChangeCheckbox(event.email)}
          />
        )}
        {event.markedSafeAt === "false" || event.markedSafeAt === "0" ? (
          <CloseIcon color={theme.colors.lightBlue["500"]} boxSize={8} mr={4} />
        ) : !onChangeCheckbox ? (
          <CheckIcon color={theme.colors.orange["600"]} boxSize={8} mr={4} />
        ) : (
          <CheckIcon
            color={theme.colors.orange["600"]}
            boxSize={8}
            mr={4}
            ml={12}
          />
        )}
        <Text fontSize={"lg"} fontWeight={"bold"} mr={4}>
          {event.firstName} {event.lastName}
        </Text>
        {event.markedSafeAt === "false" ? null : (
          <Text>
            {format(new Date(event.markedSafeAt), "MM/dd/yy hh:mm a")}
          </Text>
        )}
      </Flex>
      <Flex ml={6}>
        <EventRowLocationString event={event} />
      </Flex>
    </Box>
  );
};

const EventRowLocationString = ({
  event
}: {
  event: MMSEvent | MMSEventWithData;
}) => {
  return event.location ? (
    <a
      href={`https://maps.google.com/?q=${event.location.latitude},${event.location.longitude}`}
      target={"_blank"}
      rel={"noreferrer noopener"}
    >
      <Flex align={"center"}>
        <Text ml={8} textDecoration={"underline"}>
          View on map
        </Text>
        <ExternalLinkIcon ml={1} />
      </Flex>
    </a>
  ) : event.markedSafeAt !== "false" ? (
    <Text ml={8}>No location specified</Text>
  ) : null;
};

export default RecallManagement;
