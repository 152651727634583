export var OrganizationType;
(function (OrganizationType) {
    OrganizationType["TEAM"] = "TEAM";
    OrganizationType["FAMILY"] = "FAMILY";
    OrganizationType["TBD"] = "TBD";
})(OrganizationType || (OrganizationType = {}));
export var OrgStatus;
(function (OrgStatus) {
    OrgStatus["PENDING"] = "PENDING";
    OrgStatus["ACTIVE"] = "ACTIVE";
    OrgStatus["DEACTIVATED"] = "DEACTIVATED";
})(OrgStatus || (OrgStatus = {}));
export var DeactivatedReason;
(function (DeactivatedReason) {
    DeactivatedReason["OVERCAP"] = "overcap";
    DeactivatedReason["SUBSCRIPTION_ISSUE"] = "subscriptionIssue";
})(DeactivatedReason || (DeactivatedReason = {}));
