import { useEffect, useState } from "react";
const getWidth = () => window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
export function useCurrentWidth() {
    let [width, setWidth] = useState(getWidth());
    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };
        window.addEventListener("resize", resizeListener);
        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, []);
    return width;
}
