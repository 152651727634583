import { MMSEvent, MMSEventType, MMSEventWithData } from "../schemas/Event";
import EventRow from "./EventRow";
import { Button } from "@chakra-ui/react";
import React from "react";

export const EventList = ({
  events,
  onLoadMore,
  moreEvents,
  onClick
}: {
  events: Array<MMSEventWithData | MMSEvent>;
  onLoadMore: () => void;
  moreEvents: boolean;
  onClick?: (eventId: string) => void;
}) => (
  <>
    {events.map(event =>
      onClick && event.eventType !== MMSEventType.PREEMPTIVE ? (
        <EventRow
          event={event}
          key={event.uid}
          onClick={() => onClick(event.eventId)}
        />
      ) : (
        <EventRow event={event} key={event.uid} />
      )
    )}
    {moreEvents && <Button onClick={onLoadMore}>Load More</Button>}
  </>
);
