import React from "react";
import { Box, Button, Flex, Text, Tooltip } from "@chakra-ui/react";
import theme from "../theme";
import {
  CheckIcon,
  CloseIcon,
  ExternalLinkIcon,
  InfoIcon,
  WarningTwoIcon
} from "@chakra-ui/icons";
import { MMSEvent, MMSEventType, MMSEventWithData } from "../schemas/Event";
import format from "date-fns/format";
import { truncate } from "../utils/truncate";

interface Props {
  event: MMSEventWithData | MMSEvent;
  onClick?: () => void;
}

const eventIsMMSEventWithData = (
  event: MMSEvent | MMSEventWithData
): event is MMSEventWithData => {
  return (event as MMSEventWithData).firstName !== undefined;
};

export default function EventRow({ event, onClick }: Props) {
  return (
    <Box
      px={4}
      py={2}
      borderBottomWidth="1px"
      _last={{ borderBottomWidth: 0 }}
      w="100%"
      textAlign="left"
      h="75px"
    >
      <Flex justify="space-between" alignItems="center">
        <Box>
          <Flex alignItems="center">
            <EventRowIcon event={event} />
            <EventRowLabelString event={event} />
            <EventRowDateString event={event} />
          </Flex>
          <Flex ml={6}>
            <Tooltip label={event.description} zIndex={12000}>
              <Flex align={"center"}>
                <Text mr={4}>{truncate(event.description, 25)}</Text>
                {event.description.length > 50 && <InfoIcon />}
              </Flex>
            </Tooltip>
            <EventRowLocationString event={event} />
          </Flex>
        </Box>
        {onClick && <Button onClick={onClick}>More Details</Button>}
      </Flex>
    </Box>
  );
}

const EventRowIcon = ({ event }: { event: MMSEvent | MMSEventWithData }) => {
  if (event.eventType !== MMSEventType.PREEMPTIVE) {
    if (event.markedSafeAt === "false") {
      return (
        <WarningTwoIcon
          color={theme.colors.lightBlue["500"]}
          boxSize={8}
          mr={4}
        />
      );
    } else {
      return (
        <WarningTwoIcon color={theme.colors.orange["600"]} boxSize={8} mr={4} />
      );
    }
  }
  return event.markedSafeAt === "0" || event.markedSafeAt === "false" ? (
    <CloseIcon color={theme.colors.lightBlue["500"]} boxSize={8} mr={4} />
  ) : (
    <CheckIcon color={theme.colors.orange["600"]} boxSize={8} mr={4} />
  );
};

const EventRowLabelString = ({
  event
}: {
  event: MMSEvent | MMSEventWithData;
}) => {
  if (
    event.eventType === MMSEventType.PREEMPTIVE &&
    eventIsMMSEventWithData(event)
  ) {
    return (
      <Text fontSize={"md"} fontWeight={"bold"}>
        {event.firstName} {event.lastName}
      </Text>
    );
  }

  if (event.eventType === MMSEventType.PREEMPTIVE) {
    return (
      <Text fontSize={"md"} fontWeight={"bold"}>
        Details:
      </Text>
    );
  }

  return (
    <Text fontSize={"md"} fontWeight={"bold"}>
      Recall Details:
    </Text>
  );
};

const EventRowDateString = ({
  event
}: {
  event: MMSEvent | MMSEventWithData;
}) => {
  if (event.markedSafeAt === "0") {
    return (
      <Text ml={4}>
        Did not mark safe: Recall time -{" "}
        {format(new Date(event.eventCreatedAt), "MM/dd/yy hh:mm a")}
      </Text>
    );
  }
  if (event.markedSafeAt === "false") {
    if (event.eventType === MMSEventType.RECALL_SUMMARY) {
      return <Text ml={4}>Recall in progress</Text>;
    }
    return <Text ml={4}>Recall in progress; not marked safe yet</Text>;
  }
  return (
    <Text ml={4}>
      {format(new Date(event.markedSafeAt), "MM/dd/yy hh:mm a")}
    </Text>
  );
};

const EventRowLocationString = ({
  event
}: {
  event: MMSEvent | MMSEventWithData;
}) => {
  if (
    event.eventType !== MMSEventType.PREEMPTIVE &&
    (event.markedSafeAt === "0" || event.markedSafeAt === "false")
  ) {
    return <Text>No location specified</Text>;
  }

  return event.location ? (
    <a
      href={`https://maps.google.com/?q=${event.location.latitude},${event.location.longitude}`}
      target={"_blank"}
      rel={"noreferrer noopener"}
    >
      <Flex align={"center"}>
        <Text ml={4} textDecoration={"underline"}>
          View on map
        </Text>
        <ExternalLinkIcon ml={1} />
      </Flex>
    </a>
  ) : (
    <Text>No location specified</Text>
  );
};
