import React, { useContext, useEffect, useState } from "react";
import { useAsync } from "../hooks/useAsync";
import { Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { ScreenTitle } from "../components/ScreenTitles";
import { Loader } from "../components/Loader";
import { getErrorToast } from "../components/Toasts";
import { RFC } from "../sharedTypes";
import { navigate } from "@reach/router";
import { RouteNames } from "../navigation/routeNames";
import { loginFields, LoginSchema } from "../forms/schemas/loginSchema";
import { ForgotPasswordSchema } from "../forms/schemas/forgotPasswordSchema";
import { apiManager } from "../network/apiManager";
import { UserContext } from "../context/UserContextProvider";
import { navigateUserBasedOnRole } from "../navigation/navigationUtils";
import { ModalForm } from "../components/ModalForm";
import { FormikForm } from "../components/FormikForm";

const Login: RFC = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    currentUserInfo,
    setToken,
    userLoading,
    wrongUserType,
    setWrongUserType
  } = useContext(UserContext);
  const [userSettingLoading, setUserSettingLoading] = useState<boolean>(false);

  const loginUser = async (values: { email: string; password: string }) => {
    try {
      loginAsync.clearError();
      setUserSettingLoading(true);
      setWrongUserType(false);
      await apiManager.signIn(values, setToken);
    } catch (err) {
      console.log(err);
      setUserSettingLoading(false);
      if (err === "NOT_ADMIN") {
        setWrongUserType(true);
      } else {
        throw err;
      }
    }
  };

  useEffect(() => {
    return () => {
      toast.closeAll();
    };
  }, []);

  useEffect(() => {
    if (currentUserInfo) {
      setUserSettingLoading(false);
      navigateUserBasedOnRole(currentUserInfo);
    }
    return () => {
      loginAsync.clearError();
      setWrongUserType(false);
    };
  }, [currentUserInfo]);

  useEffect(() => {
    if (wrongUserType) {
      setUserSettingLoading(false);
    }
  }, [wrongUserType]);

  const forgotPassword = async (values: { email: string }) => {
    try {
      forgotPasswordAsync.clearError();
      onClose();
      await apiManager.forgotPassword(values);
      await navigate(RouteNames.resetPassword);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const loginAsync = useAsync<void>(
    loginUser,
    "Error logging in; please verify that email and password are correct."
  );

  const forgotPasswordAsync = useAsync<void>(
    forgotPassword,
    "Error resetting password, please verify that your email is correct"
  );

  useEffect(() => {
    if (loginAsync.error) {
      toast(getErrorToast({ description: loginAsync.error }));
    }
    if (forgotPasswordAsync.error) {
      toast(getErrorToast({ description: forgotPasswordAsync.error }));
    }
  }, [loginAsync.error, forgotPasswordAsync.error]);

  return (
    <>
      {loginAsync.pending ||
      forgotPasswordAsync.pending ||
      userSettingLoading ||
      userLoading ? (
        <Loader isLoading={true} />
      ) : (
        <Flex
          direction="column"
          w="30%"
          minW={300}
          margin="0 auto"
          mt="8"
          justifyContent="center"
          textAlign="center"
          bg="#FFF"
          p="24px"
        >
          <ScreenTitle text="Administrator Login" centered />
          <FormikForm
            handleSubmit={loginAsync.execute}
            fields={loginFields}
            validationSchema={LoginSchema}
            secondaryButton={{ label: "Forgot Password?", method: onOpen }}
          />
          <Text mt={4}>
            Don't have an account?
            <a href="https://markmesafeapp.com/register" target="_blank">
              <Text color={"blue"}>Sign Up</Text>
            </a>
          </Text>
          {wrongUserType && (
            <Text color={"tomato"}>
              Only admin accounts can use the web portal. Please download the
              mobile app with the link from your organization admin.
            </Text>
          )}
        </Flex>
      )}
      <ModalForm
        handleSubmit={forgotPasswordAsync.execute}
        fields={[
          {
            initialValue: "",
            label: "Email",
            name: "email",
            placeholder: "Email"
          }
        ]}
        title="Reset Password"
        validationSchema={ForgotPasswordSchema}
        submitButtonLabel="Request Reset Email"
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default Login;
