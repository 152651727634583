import React, { useContext, useEffect, useMemo, useRef } from "react";
import { CardBackground } from "../components/ViewContainers";
import { ScreenTitle } from "../components/ScreenTitles";
import {
  Avatar,
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { UserContext } from "../context/UserContextProvider";
import { formatter } from "../utils/formatter";
import { useAsync } from "../hooks/useAsync";
import { apiManager } from "../network/apiManager";
import theme from "../theme";
import { ProfileUpdatesSchema } from "../forms/schemas/profileUpdatesSchema";
import { Loader } from "../components/Loader";
import {
  changePasswordFields,
  ChangePasswordSchema
} from "../forms/schemas/changePasswordSchema";
import { ModalForm } from "../components/ModalForm";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { EditIcon } from "@chakra-ui/icons";
import { useLocalError } from "../hooks/useLocalError";
import { isUserPending } from "../schemas/User";

export default function Profile() {
  const {
    currentUserInfo,
    setCurrentUserInfo,
    currentUserPhotoPresignedUrl,
    setCurrentUserPhotoPresignedUrl
  } = useContext(UserContext);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const userUpdatesModal = useDisclosure();
  const photoDeleteConfirmation = useDisclosure();
  const toast = useToast();
  const changePasswordModal = useDisclosure();

  useEffect(() => {
    return () => {
      userUpdates.clearError();
      updatePhoto.clearError();
      toast.closeAll();
    };
  }, []);

  const handleChangePhoto = () => {
    inputFileRef.current?.click();
  };

  const handleFileChange = () => {
    if (inputFileRef.current?.files?.length) {
      updatePhoto.execute(inputFileRef.current.files[0]);
    }
  };

  const updateUser = async (values: {
    firstName: string;
    lastName: string;
    phoneNumber: number;
  }) => {
    userUpdates.clearError();
    userUpdatesModal.onClose();
    await apiManager.updateCurrentUser(values);
    if (currentUserInfo && !isUserPending(currentUserInfo.currentUser)) {
      setCurrentUserInfo({
        ...currentUserInfo,
        currentUser: { ...currentUserInfo.currentUser, ...values }
      });
    }
  };

  const getPhotoToUpload = async (file: File) => {
    try {
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/tiff"
      ) {
        throw new Error("Profile picture must be an image");
      }
      const newGetPresignedUrl = await apiManager.uploadUserProfileImage({
        file
      });
      if (currentUserInfo && setCurrentUserPhotoPresignedUrl) {
        setCurrentUserPhotoPresignedUrl(newGetPresignedUrl.presignedUrl);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const deleteProfilePhoto = async () => {
    try {
      if (currentUserInfo) {
        await apiManager.deleteUserPhoto();
        photoDeleteConfirmation.onClose();
        setCurrentUserPhotoPresignedUrl("");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const changePasswordValue = async (values: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    await apiManager.changePassword(values);
  };

  const userUpdates = useAsync<void>(
    updateUser,
    "Error updating profile; please check your connection and try again"
  );

  const updatePhoto = useAsync<void>(
    getPhotoToUpload,
    "Error updating photo, please check your connection and try again."
  );

  const changePassword = useAsync<void>(
    changePasswordValue,
    "Error updating your password, please try again"
  );

  const deletePhoto = useAsync<void>(
    deleteProfilePhoto,
    "Error deleting photo, please check your connection and try again"
  );

  useLocalError([userUpdates.error, updatePhoto.error]);

  const updateCurrentUserFields = useMemo(() => {
    if (currentUserInfo && !isUserPending(currentUserInfo.currentUser)) {
      return [
        {
          name: "firstName",
          label: "First Name",
          placeholder: "First Name",
          initialValue: currentUserInfo.currentUser.firstName ?? ""
        },
        {
          name: "lastName",
          label: "Last Name",
          placeholder: "Last Name",
          initialValue: currentUserInfo.currentUser.lastName ?? ""
        },
        {
          name: "phoneNumber",
          label: "Phone Number",
          placeholder: "Phone Number",
          initialValue: currentUserInfo.currentUser.phoneNumber ?? ""
        }
      ];
    } else return [];
  }, [currentUserInfo]);

  return (
    <>
      <>
        <CardBackground>
          {userUpdates.pending || updatePhoto.pending ? (
            <Loader isLoading={userUpdates.pending || updatePhoto.pending} />
          ) : (
            <>
              <Flex direction={"row"} justify={"center"}>
                <ScreenTitle text="Profile" />
                <EditIcon
                  ml={2}
                  mt={3}
                  name={"edit"}
                  onClick={userUpdatesModal.onOpen}
                />
              </Flex>
              <Flex d="column">
                <Avatar
                  src={
                    currentUserPhotoPresignedUrl
                      ? currentUserPhotoPresignedUrl
                      : ""
                  }
                  w="150px"
                  h="150px"
                  mb={2}
                />
                <Flex direction={"row"} justify={"center"}>
                  <Button
                    bg={theme.colors.darkBlue[500]}
                    color={theme.colors.white}
                    onClick={handleChangePhoto}
                    m={2}
                    p={4}
                  >
                    Change Photo
                  </Button>
                  <Button
                    bg={theme.colors.orange[500]}
                    color={theme.colors.white}
                    onClick={photoDeleteConfirmation.onOpen}
                    p={4}
                    m={2}
                    disabled={!currentUserPhotoPresignedUrl}
                  >
                    Delete Photo
                  </Button>
                </Flex>
                <input
                  style={{ opacity: 0, width: 0, height: 0 }}
                  type="file"
                  ref={inputFileRef}
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Flex>
              {currentUserInfo && !isUserPending(currentUserInfo.currentUser) && (
                <Flex direction="column" alignItems={"center"}>
                  <Text>
                    <strong>Name:</strong>{" "}
                    {currentUserInfo.currentUser.firstName}{" "}
                    {currentUserInfo.currentUser.lastName}
                  </Text>
                  {currentUserInfo.currentUser.phoneNumber && (
                    <Text>
                      <strong>Phone:</strong>{" "}
                      {formatter.formatPhoneNumber(
                        currentUserInfo.currentUser.phoneNumber
                      )}
                    </Text>
                  )}
                  <Text>
                    <strong>Email:</strong> {currentUserInfo.currentUser?.email}
                  </Text>
                  <Button
                    w="200px"
                    mt={2}
                    onClick={changePasswordModal.onOpen}
                    colorScheme="darkBlue"
                  >
                    Change Password
                  </Button>
                </Flex>
              )}
            </>
          )}
        </CardBackground>
      </>
      <ModalForm
        handleSubmit={async (values: any) => {
          await userUpdates.execute({
            ...values,
            phoneNumber: parseInt(
              typeof values.phoneNumber === "string" &&
                values.phoneNumber.indexOf("-") > -1
                ? values.phoneNumber.replaceAll(/-/g, "")
                : values.phoneNumber
            )
          });
        }}
        fields={updateCurrentUserFields}
        title="Update User Information"
        validationSchema={ProfileUpdatesSchema}
        isOpen={userUpdatesModal.isOpen}
        onClose={userUpdatesModal.onClose}
      />
      <ModalForm
        handleSubmit={async (values: any) => {
          await changePassword.execute(values);
          changePasswordModal.onClose();
        }}
        fields={changePasswordFields}
        title="Change Your Password"
        validationSchema={ChangePasswordSchema}
        isOpen={changePasswordModal.isOpen}
        topInstructionText="Password must contain: minimum of 8 characters, capital letter,
                          lowercase letter, number, & symbol (@$!%*#?&)"
        onClose={changePasswordModal.onClose}
      />
      <ConfirmationModal
        handleSubmit={deletePhoto.execute}
        bodyText="Are you sure you want to remove your profile photo?"
        isOpen={photoDeleteConfirmation.isOpen}
        onClose={photoDeleteConfirmation.onClose}
      />
    </>
  );
}
