import * as Yup from "yup";
export const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    resetCode: Yup.string().required("Required"),
    newPassword: Yup.string()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}/, "Invalid password; check requirements")
        .required("Required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), undefined], "New password must match its confirmation")
        .required("Required")
});
