import { navigate, useLocation } from "@reach/router";
export const useSetCurrentOrganization = ({ currentUserInfo, currentOrganization, orgIdInUrl, setCurrentOrganization }) => {
    const location = useLocation();
    if (!currentOrganization && !orgIdInUrl) {
        const newOrg = currentUserInfo === null || currentUserInfo === void 0 ? void 0 : currentUserInfo.organizations[0];
        if (newOrg && setCurrentOrganization) {
            setCurrentOrganization(newOrg);
            navigate(`${location.pathname}/${newOrg.organizationId}`);
        }
        else {
            navigate("/");
        }
    }
    else if (orgIdInUrl && setCurrentOrganization) {
        const currentOrg = currentUserInfo === null || currentUserInfo === void 0 ? void 0 : currentUserInfo.organizations.find(org => org.organizationId === orgIdInUrl);
        setCurrentOrganization(currentOrg);
    }
    else if (currentOrganization) {
        navigate(`${location.pathname}/${currentOrganization.organizationId}`);
    }
};
