import React, { useEffect, useState } from "react";
import { CardBackground } from "../components/ViewContainers";
import { ScreenTitle } from "../components/ScreenTitles";
import { RFC } from "../sharedTypes";
import useURLParams from "../hooks/useURLParams";
import { apiManager } from "../network/apiManager";
import { useAsync } from "../hooks/useAsync";
import { ViewPlanResponse } from "../schemas/Stripe";
import { Box, Button, FormLabel, Input, Text } from "@chakra-ui/react";
import { Loader } from "../components/Loader";
import {
  CreateOrganizationResponse,
  OrganizationType
} from "../schemas/Organization";

const RegisterByPlan: RFC = () => {
  const [productId] = useURLParams("productId");
  const [groupName, setGroupName] = useState("");
  const [email, setEmail] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (productId) {
      getPlan.execute();
    }
  }, [productId]);

  const getPlanAsync = async () => {
    try {
      return await apiManager.getPlanData(productId);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const createOrganizationAsync = async () => {
    try {
      const org = await apiManager.createOrganization({
        email: email,
        name: groupName,
        orgType: getPlan.value?.orgType ?? OrganizationType.TBD,
        productId
      });
      setHasSubmitted(true);
      return org;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const getPlan = useAsync<ViewPlanResponse>(
    getPlanAsync,
    "Error getting plan details"
  );

  const createOrganization = useAsync<CreateOrganizationResponse>(
    createOrganizationAsync,
    "Error creating organization"
  );

  return (
    <CardBackground>
      {hasSubmitted ? (
        <Text>
          Successful creation. Check your email to finish setup within 24 hours.
        </Text>
      ) : (
        <>
          <ScreenTitle text={"Register"} centered />
          {!!getPlan.error && <Text color="tomato">{getPlan.error}</Text>}
          {!!createOrganization.error && (
            <Text color="tomato">{createOrganization.error}</Text>
          )}
          {<Loader isLoading={getPlan.pending || createOrganization.pending} />}
          {getPlan.value && (
            <>
              <Box textAlign={"left"}>
                <Text>
                  <strong>Plan:</strong> {getPlan.value.name}
                </Text>
                <Text>
                  <strong>Description:</strong> {getPlan.value.description}
                </Text>
                <Text>
                  <strong>Price:</strong> {getPlan.value.unitPrice}
                </Text>
                <a
                  rel={"noopener noreferrer"}
                  href={"https://markmesafeapp.com/register"}
                >
                  <Button colorScheme={"orange"}>Change Plan Type</Button>
                </a>
              </Box>
              <Box mt={4}>
                <FormLabel>Group Name</FormLabel>
                <Input
                  value={groupName}
                  onChange={e => setGroupName(e.target.value)}
                />
                <FormLabel>Email</FormLabel>
                <Input value={email} onChange={e => setEmail(e.target.value)} />
                <Button
                  mt={4}
                  w={"200px"}
                  colorScheme={"darkBlue"}
                  onClick={createOrganization.execute}
                  isDisabled={!email || !groupName}
                >
                  Submit
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </CardBackground>
  );
};

export default RegisterByPlan;
