import React, { ReactNode } from "react";
import styled from "styled-components";
import Theme from "../theme";
import { Flex } from "@chakra-ui/react";

export function ViewContainer({
  children,
  loadedImage,
  width
}: {
  children: ReactNode;
  loadedImage: ReactNode;
  width: number;
}): JSX.Element {
  return (
    <>
      {loadedImage}
      <ViewContainerStyled width={width}>{children}</ViewContainerStyled>
    </>
  );
}

export function CardBackground({
  children
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <Flex
      direction="column"
      w="90%"
      maxW={600}
      margin="0 auto"
      my="8"
      justifyContent="center"
      textAlign="center"
      bg="#FFF"
      p="24px"
    >
      {children}
    </Flex>
  );
}

const ViewContainerStyled = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  height: ${window.innerHeight - 120}px;
  padding: 1%;
  text-align: center;
  display: flex;
  flex: 1;
  color: ${Theme.colors.darkBlue[500]};
  flex-direction: column;
  overscroll-behavior: none;
`;

export const BackgroundImage = styled.img`
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
    width: 100%;
  height: auto;
  min-height: 100%;
  min-width: 2000px;
  @media screen and (max-width: 2000px) { /* Specific to this particular image */
  img.bg {
    left: 50%;
    margin-left: -1000px;   /* 50% */
  }
`;
