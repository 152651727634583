import React, { useContext, useEffect, useState } from "react";
import { CardBackground } from "../components/ViewContainers";
import { ScreenTitle } from "../components/ScreenTitles";
import { useAsync } from "../hooks/useAsync";
import { Loader } from "../components/Loader";
import { apiManager } from "../network/apiManager";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { AddIcon, RepeatIcon } from "@chakra-ui/icons";
import {
  DeactivatedReason,
  Organization,
  OrganizationType,
  OrgStatus,
  ViewOrganizationResponse
} from "../schemas/Organization";
import theme from "../theme";
import { truncate } from "../utils/truncate";
import { UserContext } from "../context/UserContextProvider";
import { navigate } from "@reach/router";
import { RouteNames } from "../navigation/routeNames";
import {
  User,
  UserRole,
  UserStatus,
  ViewUserInOrgResponse
} from "../schemas/User";
import { ViewPlanResponse } from "../schemas/Stripe";

export default function OrganizationList() {
  const { currentUserInfo, setCurrentOrganization } = useContext(UserContext);
  const [plans, setPlans] = useState<ViewPlanResponse[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const [selectedOrgId, setSelectedOrgId] = useState<string>("");
  const [newOrgName, setNewOrgName] = useState<string>("");
  const [isCreatingNew, setIsCreatingNew] = useState<boolean>(false);
  const [seatsToRemove, setSeatsToRemove] = useState<number | null>(null);
  const [activeUsers, setActiveUsers] = useState<ViewUserInOrgResponse[]>([]);
  const [billingPortalUrl, setBillingPortalUrl] = useState<string>("");
  const planSelectModal = useDisclosure();
  const deactivateUsersModal = useDisclosure();

  const fetchOrganizations = async () => {
    try {
      organizations.clearError();
      return apiManager.getOrganizations();
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateUserCurrentOrg = async orgId => {
    try {
      updateUserOrg.clearError();
      const newOrg = currentUserInfo?.organizations.find(
        org => org.organizationId === orgId
      );
      if (newOrg) {
        setCurrentOrganization(newOrg);
        await navigate(`${RouteNames.users}/${orgId}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReactivatingOrgAsync = async ({ org }: { org: Organization }) => {
    if (
      org?.deactivatedReason &&
      org.deactivatedReason === DeactivatedReason.SUBSCRIPTION_ISSUE
    ) {
      await handleSubscriptionIssue({
        orgId: org.organizationId,
        orgType: org.orgType
      });
      planSelectModal.onOpen();
    } else if (
      org?.deactivatedReason &&
      org.deactivatedReason === DeactivatedReason.OVERCAP
    ) {
      deactivateUsersModal.onOpen();
      await handleOvercap({ orgId: org.organizationId });
    }
  };

  const handleSubscriptionIssue = async ({
    orgId,
    orgType
  }: {
    orgId: string;
    orgType: OrganizationType;
  }) => {
    try {
      const response = await apiManager.startBillingPortalSession(orgId);
      navigate(response.url);
    } catch (err) {
      if (err.name === "NO_SUBSCRIPTION") {
        setSelectedOrgId(orgId);
        setIsCreatingNew(false);
        await viewAllPlansAsync({ orgType });
        planSelectModal.onOpen();
      }
      console.log(err);
      throw err;
    }
  };

  const handleOvercap = async ({ orgId }: { orgId: string }) => {
    // get the billing portal link
    setSelectedOrgId(orgId);
    const billingPortalResponse = await apiManager.startBillingPortalSession(
      orgId
    );
    setBillingPortalUrl(billingPortalResponse.url);
    // get the number of seats
    const seatStatus = await apiManager.validateSeatStatus(orgId);
    setSeatsToRemove(seatStatus.currentUsers - seatStatus.maxSeats);
    // get all users & filter out the deactivated ones
    const allUsers = await apiManager.getUsers(orgId);
    const filteredUsers = allUsers.filter(
      user =>
        user.status !== UserStatus.DEACTIVATED &&
        user.userId !== currentUserInfo?.currentUser.userId
    );
    setActiveUsers(filteredUsers);
  };

  const deactivateSelectedUserAsync = async ({
    orgId,
    email
  }: {
    orgId: string;
    email: string;
  }) => {
    try {
      await apiManager.toggleUserActivation({
        organizationId: orgId,
        status: UserStatus.DEACTIVATED,
        email
      });
      setActiveUsers(prevState =>
        prevState.filter(item => item.email !== email)
      );
      if (seatsToRemove && seatsToRemove > 1) {
        setSeatsToRemove(prevState => prevState! - 1);
      } else {
        setSeatsToRemove(null);
        setActiveUsers([]);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  const viewAllPlansAsync = async ({
    orgType
  }: {
    orgType?: OrganizationType;
  }) => {
    try {
      const allPlans = await apiManager.viewAllPlans(orgType);
      setPlans(allPlans.plans);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const createNewOrgAsync = async () => {
    try {
      if (currentUserInfo) {
        const planOrgType = plans.find(plan => plan.productId === selectedPlan)
          ?.orgType;
        const newOrg = await apiManager.createOrganization({
          email: currentUserInfo.currentUser.email,
          name: newOrgName,
          orgType: planOrgType ?? OrganizationType.TBD,
          productId: selectedPlan
        });
        const redirectUrl = await apiManager.createCheckoutSession({
          organizationId: newOrg.organization.organizationId,
          productId: selectedPlan
        });
        navigate(redirectUrl);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const createCheckoutSessionAsync = async () => {
    try {
      const redirectUrl = await apiManager.createCheckoutSession({
        organizationId: selectedOrgId,
        productId: selectedPlan
      });
      navigate(redirectUrl);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const organizations = useAsync<ViewOrganizationResponse[]>(
    fetchOrganizations,
    "Error retrieving organization list. Check your connection and try again."
  );

  const updateUserOrg = useAsync<void>(
    updateUserCurrentOrg,
    "Error updating your organization. Please try again."
  );

  const createNewOrg = useAsync<void>(
    createNewOrgAsync,
    "Error updating your organization. Please try again."
  );

  const handleReactivatingOrg = useAsync<void>(
    handleReactivatingOrgAsync,
    "Error reactivating"
  );

  const viewAllPlans = useAsync<void>(
    viewAllPlansAsync,
    "Error getting available plans."
  );

  const deactivateSelectedUser = useAsync<void>(
    deactivateSelectedUserAsync,
    "Error getting available plans."
  );

  const createCheckoutSession = useAsync<void>(
    createCheckoutSessionAsync,
    "Error redirecting"
  );

  useEffect(() => {
    organizations.execute();
  }, []);

  return (
    <>
      <CardBackground>
        <ScreenTitle text="Organizations" centered />
        {organizations.pending ? (
          <Loader isLoading={organizations.pending || updateUserOrg.pending} />
        ) : (
          <Flex align="flex-end" direction="column" marginTop={4}>
            <Flex direction="row" justify={"space-between"} w={"100%"}>
              <Button
                colorScheme="darkBlue"
                w={"40px"}
                onClick={organizations.execute}
                mb={2}
              >
                <RepeatIcon />
              </Button>
              <Button
                colorScheme="darkBlue"
                w={"200px"}
                mb={2}
                leftIcon={<AddIcon />}
                onClick={() => {
                  setIsCreatingNew(true);
                  viewAllPlans.execute({});
                  planSelectModal.onOpen();
                }}
              >
                Add Organization
              </Button>
            </Flex>
            <Tabs w="100%" variant="unstyled">
              <TabList>
                <Tab _selected={{ color: "darkBlue.500", bg: "lime.500" }}>
                  Active Organizations
                </Tab>
                <Tab _selected={{ color: "darkBlue.500", bg: "lime.500" }}>
                  Inactive Organizations
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Box
                    borderWidth="1px"
                    rounded="md"
                    w="100%"
                    m="10px"
                    overflow={"auto"}
                    maxH={window.innerWidth * 0.7}
                  >
                    {organizations.value
                      ?.filter(org => org.active === OrgStatus.ACTIVE)
                      .map(org => (
                        <ActiveOrganizationRow
                          key={org.organizationId}
                          logoSrc={org?.presignedUrl ?? ""}
                          orgName={org.name}
                          setAsCurrent={() =>
                            updateUserOrg.execute(org.organizationId)
                          }
                          userInfoInOrg={currentUserInfo?.userInOrgItems.find(
                            user => user.organizationId === org.organizationId
                          )}
                        />
                      ))}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box
                    borderWidth="1px"
                    m="10px"
                    maxH={window.innerWidth * 0.7}
                    overflow={"auto"}
                    rounded="md"
                    w="100%"
                  >
                    {organizations.value
                      ?.filter(org => org.active !== OrgStatus.ACTIVE)
                      .map(org => (
                        <InactiveOrganizationRow
                          key={org.organizationId}
                          logoSrc={org?.presignedUrl ?? ""}
                          orgName={org.name}
                          reactivateOrg={() =>
                            handleReactivatingOrg.execute({ org })
                          }
                        />
                      ))}
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        )}
        <Modal
          blockScrollOnMount={false}
          isOpen={planSelectModal.isOpen}
          onClose={planSelectModal.onClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent w={["90%", "80%", "70%", "60%"]}>
            <ModalHeader color={theme.colors.darkBlue[500]}>
              Select a Plan
            </ModalHeader>
            <ModalCloseButton />
            {viewAllPlans.pending ||
            createCheckoutSession.pending ||
            createNewOrg.pending ||
            handleReactivatingOrg.pending ? (
              <Loader isLoading={true} />
            ) : (
              <ModalBody>
                <RadioGroup
                  onChange={(val: string) => setSelectedPlan(val)}
                  value={selectedPlan}
                  mb={2}
                >
                  <Stack direction="row">
                    {!!plans.length &&
                      plans.map(plan => (
                        <Radio value={plan.productId}>{plan.name}</Radio>
                      ))}
                  </Stack>
                </RadioGroup>
                {isCreatingNew && (
                  <>
                    <FormLabel>New Organization Name</FormLabel>
                    <Input
                      value={newOrgName}
                      onChange={e => setNewOrgName(e.target.value)}
                    />
                  </>
                )}
              </ModalBody>
            )}
            <ModalFooter>
              <Button
                colorScheme="darkBlue"
                onClick={() => {
                  if (isCreatingNew) {
                    createNewOrg.execute();
                  } else {
                    createCheckoutSession.execute();
                  }
                }}
              >
                Pay with Stripe
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          blockScrollOnMount={false}
          isOpen={deactivateUsersModal.isOpen}
          onClose={deactivateUsersModal.onClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent w={"600px"}>
            <ModalHeader color={theme.colors.darkBlue[500]}>
              Deactivate Users
            </ModalHeader>
            <ModalCloseButton />
            {handleReactivatingOrg.pending || deactivateSelectedUser.pending ? (
              <Loader isLoading={true} />
            ) : (
              <ModalBody>
                <>
                  {!!deactivateSelectedUser.error && (
                    <Text color={"tomato"}>{deactivateSelectedUser.error}</Text>
                  )}
                  <Text>
                    You have more users than active seats. To reactivate your
                    org, either add more seats in{" "}
                    <Text
                      as={"a"}
                      rel={"noreferrer noopener"}
                      target={"_blank"}
                      href={billingPortalUrl}
                      textDecoration={"underline"}
                    >
                      your billing portal
                    </Text>{" "}
                    or deactivate users.
                  </Text>
                  <Text mt={2}>Users over current cap: {seatsToRemove}</Text>
                  <Box>
                    {activeUsers.map(user => (
                      <Box key={user.userId}>
                        {user.status === UserStatus.PENDING ? (
                          <Button
                            variant="link"
                            onClick={() =>
                              deactivateSelectedUser.execute({
                                orgId: selectedOrgId ?? "",
                                email: user.email
                              })
                            }
                            ml={2}
                          >
                            <Text textOverflow={"wrap"} maxW={50}>
                              Deactivate {user.email}
                              <br /> (Pending)
                            </Text>
                          </Button>
                        ) : (
                          <Button
                            variant="link"
                            onClick={() =>
                              deactivateSelectedUser.execute({
                                orgId: selectedOrgId ?? "",
                                email: user.email
                              })
                            }
                            ml={2}
                          >
                            <Text textOverflow={"wrap"} maxW={50}>
                              Deactivate {user.email} <br />(
                              {`${user.firstName} ${user.lastName}`})
                            </Text>
                          </Button>
                        )}
                      </Box>
                    ))}
                  </Box>
                </>
              </ModalBody>
            )}
            <ModalFooter>
              <Button
                colorScheme="darkBlue"
                onClick={deactivateUsersModal.onClose}
              >
                Done
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </CardBackground>
    </>
  );
}

const ActiveOrganizationRow = ({
  logoSrc,
  orgName,
  setAsCurrent,
  userInfoInOrg
}: {
  logoSrc: string;
  orgName: string;
  setAsCurrent: () => void;
  userInfoInOrg: User | undefined;
}) => {
  const userIsActive = userInfoInOrg?.status === UserStatus.ACTIVE;
  const userIsDeactivated = userInfoInOrg?.status !== UserStatus.ACTIVE;
  const userIsAdmin = userInfoInOrg?.userRole === UserRole.ADMIN;
  const userIsNotAdmin = userInfoInOrg?.userRole !== UserRole.ADMIN;

  return (
    <Box
      px={4}
      py={4}
      borderBottomWidth="1px"
      textAlign="left"
      h="100px"
      _hover={
        userIsActive && userIsAdmin
          ? {
              borderColor: "gray.200",
              bg: theme.colors.lime[500]
            }
          : {}
      }
      cursor={userIsActive && userIsAdmin ? "pointer" : undefined}
      m={"auto"}
      onClick={userIsActive && userIsAdmin ? setAsCurrent : () => {}}
    >
      <Flex justify="space-between" alignItems={"center"}>
        <Flex direction="row" alignItems="center" justifyContent={"center"}>
          <Avatar src={logoSrc} name={orgName} size="lg" />
          <Text fontWeight="bold" fontSize="xl" ml={4}>
            {truncate(orgName, 50)}
          </Text>
        </Flex>
      </Flex>
      {userIsDeactivated && userIsAdmin && (
        <Text
          ml={12}
          mt={-2}
          color={theme.colors.orange["500"]}
          fontSize={"sm"}
        >
          You are deactivated in this org. Contact another admin.
        </Text>
      )}
      {userIsActive && userIsNotAdmin && (
        <Text ml={12} mb={4} color={theme.colors.orange["500"]} fontSize={"sm"}>
          You aren't an admin in this org. Please use the mobile app.
        </Text>
      )}
      {userIsDeactivated && userIsNotAdmin && (
        <Text
          ml={12}
          mt={-2}
          color={theme.colors.orange["500"]}
          fontSize={"sm"}
        >
          You are a deactivated member. Contact an admin.
        </Text>
      )}
    </Box>
  );
};

const InactiveOrganizationRow = ({
  logoSrc,
  orgName,
  reactivateOrg
}: {
  logoSrc: string;
  orgName: string;
  reactivateOrg: () => void;
}) => {
  return (
    <Box
      px={4}
      py={4}
      borderBottomWidth="1px"
      textAlign="left"
      h="100px"
      m={"auto"}
    >
      <Flex justify="space-between" alignItems={"center"}>
        <Flex direction="row" alignItems="center" justifyContent={"center"}>
          <Avatar src={logoSrc} name={orgName} size="lg" />
          <Text fontWeight="bold" fontSize="xl" ml={4}>
            {truncate(orgName, 50)}
          </Text>
          <Button colorScheme={"orange"} ml={8} onClick={reactivateOrg}>
            Reactivate Org
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
