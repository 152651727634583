import React, { useContext } from "react";
import { RFC } from "../sharedTypes";
import { RouteComponentProps, useParams } from "@reach/router";
import Unauthorized from "../pages/Unauthorized";
import { UserContext } from "../context/UserContextProvider";
import { Loader } from "../components/Loader";
import { UserRole } from "../schemas/User";
import { OrgStatus } from "../schemas/Organization";

interface Props extends RouteComponentProps {
  component: RFC<any>;
  path: string;
}

export const AuthRoute: RFC<Props> = ({
  component: RouteComponent,
  ...routeProps
}) => {
  const { currentUserInfo, userLoading, currentOrganization } = useContext(
    UserContext
  );

  if (userLoading) {
    return <Loader isLoading={userLoading} />;
  }

  return currentUserInfo ? (
    <RouteComponent {...routeProps} />
  ) : (
    <Unauthorized />
  );
};

export const AuthRouteWithParams: RFC<Props> = ({
  component: RouteComponent,
  ...routeProps
}) => {
  const { currentUserInfo, userLoading, currentOrganization } = useContext(
    UserContext
  );
  const { id } = useParams();

  const currentUserIsAuthorized = currentUserInfo?.userInOrgItems.find(
    userItem =>
      userItem.userRole === UserRole.ADMIN && userItem.organizationId === id
  );

  const currentOrgIsNotDeactivated =
    !currentOrganization ||
    (currentOrganization && currentOrganization.active === OrgStatus.ACTIVE);

  if (userLoading) {
    return <Loader isLoading={userLoading} />;
  }

  return currentUserIsAuthorized && currentOrgIsNotDeactivated ? (
    <RouteComponent {...routeProps} />
  ) : (
    <Unauthorized />
  );
};
