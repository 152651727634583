import { extendTheme } from "@chakra-ui/react";
export default extendTheme({
    fonts: {
        heading: '"Avenir Next", sans-serif',
        body: "system-ui, sans-serif",
        mono: "Menlo, monospace"
    },
    fontSizes: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "4rem"
    },
    fontColors: {
        title: "#f1f1f1",
        spinner: "red"
    },
    colors: {
        darkBlue: {
            500: "#003f80"
        },
        lime: {
            500: "#8dc63f"
        },
        lightBlue: {
            500: "#85d2dd"
        },
        darkGrey: {
            500: "#939598"
        },
        orange: {
            100: "#ed8922",
            200: "#ed8922",
            300: "#ed8922",
            400: "#ed8922",
            500: "#ed8922",
            600: "#ed8922",
            700: "#ed8922",
            800: "#ed8922",
            900: "#ed8922"
        },
        mediumBlue: {
            500: "#1b9dd9"
        },
        yellowGreen: {
            500: "#dce57d"
        },
        lightGray: {
            500: "#e6e7e8"
        }
    }
});
