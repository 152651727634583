import React, { useContext } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text
} from "@chakra-ui/react";
import Theme from "../theme";
import theme from "../theme";
import { apiManager } from "../network/apiManager";
import { navigate } from "@reach/router";
import { RouteNames } from "../navigation/routeNames";
import { UserContext } from "../context/UserContextProvider";
import { HamburgerIcon } from "@chakra-ui/icons";
import { UserStatus } from "../schemas/User";
import { formatter } from "../utils/formatter";
import { OrgStatus } from "../schemas/Organization";
import { useAsync } from "../hooks/useAsync";
import { Loader } from "./Loader";

export default function Navbar() {
  const {
    currentUserInfo,
    setToken,
    currentUserPhotoPresignedUrl,
    currentOrganization
  } = useContext(UserContext);

  const handleBillingPortalClick = async () => {
    try {
      if (currentOrganization) {
        const response = await apiManager.startBillingPortalSession(
          currentOrganization.organizationId
        );
        navigate(response.url);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const signOut = async () => {
    await apiManager.signOut(setToken);
    await navigate(RouteNames.root);
  };

  const goToBillingPortal = useAsync<void>(
    handleBillingPortalClick,
    "Cannot get link to billing portal, please" + " contact" + " support"
  );

  return (
    <Box
      color="white"
      p={4}
      w="100%"
      h="120px"
      justifyContent="center"
      alignItems={"center"}
    >
      <Flex justify="space-between" align="center">
        <Image
          maxW={window.innerWidth * 0.3}
          marginRight={4}
          src={require("../assets/logos/Mark-Me-Safe-logo-color-white-web-400.png")}
        />
        <Flex direction="row" alignItems="center">
          <>
            {currentUserInfo?.currentUser.status !== UserStatus.DEACTIVATED &&
              currentOrganization?.active === OrgStatus.ACTIVE && (
                <Stack
                  direction={"row"}
                  spacing={12}
                  mr={[0, 0, 0, 8, 12]}
                  display={["none", "none", "none", "inherit", "inherit"]}
                >
                  <Text
                    fontSize={"lg"}
                    fontWeight="bold"
                    onClick={() =>
                      navigate(
                        RouteNames.users +
                          `/${currentOrganization?.organizationId}`
                      )
                    }
                    textTransform={"uppercase"}
                    _hover={{
                      color: theme.colors.orange["500"],
                      cursor: "pointer"
                    }}
                  >
                    User List
                  </Text>
                  <Text
                    fontSize={"lg"}
                    fontWeight="bold"
                    onClick={() => navigate(RouteNames.safeList)}
                    textTransform={"uppercase"}
                    _hover={{
                      color: theme.colors.orange["500"],
                      cursor: "pointer"
                    }}
                  >
                    Safe List
                  </Text>
                  <Text
                    fontSize={"lg"}
                    fontWeight="bold"
                    onClick={() => navigate(RouteNames.recall)}
                    textTransform={"uppercase"}
                    _hover={{
                      color: theme.colors.orange["500"],
                      cursor: "pointer"
                    }}
                  >
                    Recalls
                  </Text>
                </Stack>
              )}
            {currentUserInfo?.currentUser.status !== UserStatus.PENDING && (
              <>
                <Box textAlign={"right"}>
                  <Text fontSize="md" fontWeight="bold">
                    Hello {currentUserInfo?.currentUser.firstName}!
                  </Text>
                  {currentOrganization?.active === OrgStatus.ACTIVE && (
                    <Text fontSize="sm">
                      Current{" "}
                      {formatter.capitalize(currentOrganization?.orgType ?? "")}
                      : {currentOrganization?.name ?? "Not selected"}
                    </Text>
                  )}
                </Box>
                {currentUserPhotoPresignedUrl ? (
                  <Button w="50px" h="50px" borderRadius="25px" ml={4}>
                    <Avatar
                      src={currentUserPhotoPresignedUrl}
                      size="lg"
                      w="50px"
                      h="50px"
                      bg={Theme.colors.orange[500]}
                      color={Theme.colors.white}
                      _hover={{
                        bg: Theme.colors.orange[500],
                        cursor: "default"
                      }}
                    />
                  </Button>
                ) : (
                  <Button
                    bg={Theme.colors.orange[500]}
                    w="50px"
                    h="50px"
                    borderRadius="25px"
                    color={Theme.colors.white[500]}
                    ml={4}
                    _hover={{ bg: Theme.colors.orange[500], cursor: "default" }}
                  >
                    {currentUserInfo?.currentUser.firstName[0]}
                    {currentUserInfo?.currentUser.lastName[0]}
                  </Button>
                )}
              </>
            )}
          </>
          {currentUserInfo && (
            <Menu>
              <MenuButton mx={4}>
                <HamburgerIcon bg={"transparent"} h={"50px"} w={"50px"} p={2} />
              </MenuButton>
              <MenuList
                bg={Theme.colors.lime[500]}
                color={Theme.colors.darkBlue[500]}
              >
                {currentUserInfo.currentUser.status !==
                  UserStatus.DEACTIVATED &&
                  currentOrganization?.active === OrgStatus.ACTIVE && (
                    <Box
                      display={[
                        "inherit",
                        "inherit",
                        "inherit",
                        "none",
                        "none"
                      ]}
                    >
                      <MenuItem
                        fontWeight="bold"
                        onClick={() => navigate(RouteNames.users)}
                      >
                        User List
                      </MenuItem>
                      <MenuItem
                        fontWeight="bold"
                        onClick={() => navigate(RouteNames.safeList)}
                      >
                        Safe List
                      </MenuItem>
                      <MenuItem
                        fontWeight="bold"
                        onClick={() => navigate(RouteNames.recall)}
                      >
                        Recalls
                      </MenuItem>
                    </Box>
                  )}
                {currentUserInfo.currentUser.status !==
                  UserStatus.DEACTIVATED && (
                  <MenuItem
                    fontWeight="bold"
                    onClick={() => navigate(RouteNames.organizations)}
                  >
                    Organizations
                  </MenuItem>
                )}
                <MenuItem fontWeight="bold" onClick={goToBillingPortal.execute}>
                  {goToBillingPortal.pending ? (
                    <Loader isLoading={goToBillingPortal.pending} />
                  ) : (
                    "Billing Portal"
                  )}
                </MenuItem>
                <MenuItem
                  fontWeight="bold"
                  onClick={() => navigate(RouteNames.profile)}
                >
                  Profile
                </MenuItem>
                <MenuItem fontWeight="bold" onClick={signOut}>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
