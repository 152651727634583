import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import Theme from "../theme";

export default function NavbarNonAuth() {
  return (
    <Flex
      bg={Theme.colors.darkBlue}
      p={4}
      w="100%"
      h="120px"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        src={require("../assets/logos/Mark-Me-Safe-logo-color-white-web-400.png")}
        m="0 auto"
        maxW={window.innerWidth * 0.5}
      />
    </Flex>
  );
}
