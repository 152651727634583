import React, { useContext, useEffect, useState } from "react";
import { CardBackground } from "../components/ViewContainers";
import { ScreenTitle } from "../components/ScreenTitles";
import { Button, Stack, Text, useToast } from "@chakra-ui/react";
import { Loader } from "../components/Loader";
import { useAsync } from "../hooks/useAsync";
import {
  registerFields,
  RegisterSchema
} from "../forms/schemas/registerSchema";
import useURLParams from "../hooks/useURLParams";
import { apiManager } from "../network/apiManager";
import { RFC } from "../sharedTypes";
import { UserContext } from "../context/UserContextProvider";
import { FormikForm } from "../components/FormikForm";
import { navigate } from "@reach/router";

const InitialRegister: RFC = () => {
  const toast = useToast();
  let [email] = useURLParams("email");
  email = decodeURIComponent(email);
  let [verificationCode] = useURLParams("verificationCode");
  verificationCode = decodeURIComponent(verificationCode);
  let [productId] = useURLParams("productId");
  productId = decodeURIComponent(productId);
  const { setToken, currentUserInfo } = useContext(UserContext);
  const [hasValidated, setHasValidated] = useState<boolean>(false);
  const [hasRegistered, setHasRegistered] = useState<boolean>(false);
  const validateInvitationAsync = async () => {
    try {
      await apiManager.validateInvitation({
        email,
        verificationCode
      });
      setHasValidated(true);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const signupViaInvitationAsync = async values => {
    try {
      signupViaInvitation.clearError();
      const body = {
        ...values,
        phoneNumber: parseInt(values.phoneNumber),
        newPassword: values.password,
        invitationCode: verificationCode
      };
      delete body.password;
      delete body.confirmPassword;
      await apiManager.signupViaInvitation(body, setToken);
      setHasRegistered(true);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const createCheckoutSessionAsync = async () => {
    try {
      if (currentUserInfo) {
        const redirectUrl = await apiManager.createCheckoutSession({
          organizationId: currentUserInfo.currentUser.organizationId,
          productId: productId
        });
        navigate(redirectUrl);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const signupViaInvitation = useAsync<void>(
    signupViaInvitationAsync,
    "Error signing up; please verify your email address and check your connection."
  );

  const validateInvitation = useAsync<void>(
    validateInvitationAsync,
    "Invalid invitation"
  );

  const createCheckoutSession = useAsync<void>(
    createCheckoutSessionAsync,
    "Failed to redirect"
  );

  useEffect(() => {
    if (currentUserInfo) {
      setToken("");
    }
    validateInvitation.execute();
    return () => {
      validateInvitation.clearError();
      signupViaInvitation.clearError();
      toast.closeAll();
    };
  }, []);

  return (
    <>
      {signupViaInvitation.pending ||
      validateInvitation.pending ||
      (hasRegistered && !currentUserInfo) ? (
        <Loader
          isLoading={signupViaInvitation.pending && validateInvitation.pending}
        />
      ) : hasRegistered && currentUserInfo ? (
        <CardBackground>
          <Text mb={4}>Successfully registered this account.</Text>
          <Stack align={"center"}>
            {createCheckoutSession.pending ? (
              <Loader isLoading={createCheckoutSession.pending} />
            ) : (
              <Button
                colorScheme="darkBlue"
                w={"200px"}
                onClick={createCheckoutSession.execute}
              >
                Pay via Stripe to activate
              </Button>
            )}
          </Stack>
        </CardBackground>
      ) : (
        !hasRegistered && (
          <CardBackground>
            <ScreenTitle text="Register" centered />
            {!!validateInvitation.error ? (
              <>
                <Text color="tomato">{validateInvitation.error}</Text>
                <Stack align={"center"}>
                  <Button maxW={"200px"} colorScheme={"orange"} mt={4}>
                    Request New Invitation
                  </Button>
                </Stack>
              </>
            ) : !!signupViaInvitation.error ? (
              <Text color="tomato">{signupViaInvitation.error}</Text>
            ) : (
              hasValidated && (
                <FormikForm
                  handleSubmit={signupViaInvitation.execute}
                  fields={registerFields(email)}
                  validationSchema={RegisterSchema}
                  topInstructionText="Password must contain: minimum of 8 characters, capital letter,
                          lowercase letter, number, & symbol (@$!%*#?&)"
                />
              )
            )}
          </CardBackground>
        )
      )}
    </>
  );
};

export default InitialRegister;
