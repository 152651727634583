import React from "react";
import { RFC } from "../sharedTypes";
import { CardBackground } from "../components/ViewContainers";
import { ScreenTitle } from "../components/ScreenTitles";

const NotFound: RFC = () => (
  <>
    <CardBackground>
      <ScreenTitle text="Page Not Found" centered />
    </CardBackground>
  </>
);

export default NotFound;
