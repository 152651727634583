import { Field, Formik } from "formik";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text
} from "@chakra-ui/react";
import theme from "../theme";
import React from "react";
import { FormikField } from "../schemas/Forms";

interface Props {
  handleSubmit: (values?: any) => any;
  fields: FormikField[];
  validationSchema: any;
  submitButtonLabel?: string;
  secondaryButton?: { label: string; method: () => any };
  topInstructionText?: string;
}

export function FormikForm({
  handleSubmit,
  fields,
  validationSchema,
  submitButtonLabel = "Submit",
  secondaryButton,
  topInstructionText
}: Props) {
  const initialValues: { [key: string]: any } = {};
  fields.forEach(field => {
    initialValues[field.name] = field.initialValue;
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        await handleSubmit(values);
        actions.setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          {!!topInstructionText && <Text>{topInstructionText}</Text>}
          {fields.map(passedField => (
            <Field name={passedField.name} key={passedField.name}>
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors[passedField.name] &&
                    form.touched[passedField.name]
                  }
                >
                  <FormLabel
                    color={theme.colors.darkBlue[500]}
                    htmlFor={passedField.name}
                  >
                    {passedField.label}
                  </FormLabel>
                  <Input
                    {...field}
                    id={passedField.name}
                    placeholder={passedField.placeholder}
                    type={passedField.password ? "password" : undefined}
                    disabled={passedField.disabled ?? false}
                  />
                  <FormErrorMessage>
                    {form.errors[passedField.name]}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          ))}
          <Flex direction="column">
            <Button
              mt={4}
              colorScheme="darkBlue"
              isLoading={props.isSubmitting}
              type="submit"
            >
              {submitButtonLabel}
            </Button>
            {!!secondaryButton && (
              <Button
                mt={4}
                variant="outline"
                colorScheme="darkBlue"
                type="button"
                onClick={secondaryButton.method}
              >
                {secondaryButton.label}
              </Button>
            )}
          </Flex>
        </form>
      )}
    </Formik>
  );
}
