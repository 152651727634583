import * as Yup from "yup";
export const InviteUserSchema = Yup.object().shape({
    email: Yup.array()
        .transform(function (value, originalValue) {
        //@ts-ignore
        if (this.isType(value) && value !== null) {
            return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
        .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
});
export const inviteAdminFields = [
    {
        name: "email",
        label: "Invite New Admin",
        placeholder: "Admin Email",
        initialValue: ""
    }
];
