import React, { Dispatch, SetStateAction } from "react";
import { Field, Formik } from "formik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import theme from "../theme";
import { FormikField } from "../schemas/Forms";
import { OrganizationType } from "../schemas/Organization";

interface Props {
  handleSubmit: (values?: any) => any;
  fields: FormikField[];
  title: string;
  validationSchema: any;
  submitButtonLabel?: string;
  isOpen: boolean;
  onClose: () => void;
  submissionInstructions?: string;
  topInstructionText?: string;
  addOrgTypeSelect?: {
    value: string;
    setValue: Dispatch<SetStateAction<OrganizationType>>;
  };
  emailErrors?: Array<{ email: string; error: string }>;
  submitButtonDisabled?: boolean;
}

export function ModalForm({
  handleSubmit,
  fields,
  title,
  validationSchema,
  submitButtonLabel = "Submit",
  isOpen,
  onClose,
  submissionInstructions,
  topInstructionText,
  addOrgTypeSelect,
  submitButtonDisabled,
  emailErrors
}: Props) {
  const initialValues: { [key: string]: any } = {};
  fields.forEach(field => {
    initialValues[field.name] = field.initialValue;
  });

  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent w={["90%", "80%", "70%", "60%"]}>
        <ModalHeader color={theme.colors.darkBlue[500]}>{title}</ModalHeader>
        <ModalCloseButton />
        {!!topInstructionText && (
          <Text color={theme.colors.darkBlue[500]} align={"center"}>
            {topInstructionText}
          </Text>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            await handleSubmit(values);
            actions.setSubmitting(false);
          }}
          validationSchema={validationSchema}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                {fields.map(passedField => (
                  <Field name={passedField.name} key={passedField.name}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors[passedField.name] &&
                          form.touched[passedField.name]
                        }
                      >
                        <FormLabel
                          color={theme.colors.darkBlue[500]}
                          htmlFor={passedField.name}
                        >
                          {passedField.label}
                        </FormLabel>
                        <Input
                          {...field}
                          id={passedField.name}
                          placeholder={passedField.placeholder}
                          type={
                            passedField.name === "oldPassword" ||
                            passedField.name === "newPassword" ||
                            passedField.name === "confirmPassword"
                              ? "password"
                              : undefined
                          }
                        />
                        <FormErrorMessage>
                          {form.errors[passedField.name]}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                ))}
                {addOrgTypeSelect && (
                  <Flex
                    direction={"row"}
                    align={"center"}
                    justify={"space-around"}
                    mt={4}
                  >
                    <div>
                      <FormLabel
                        htmlFor="team"
                        color={theme.colors.darkBlue[500]}
                      >
                        Team
                      </FormLabel>
                      <input
                        type={"radio"}
                        id={OrganizationType.TEAM}
                        value={OrganizationType.TEAM}
                        checked={
                          addOrgTypeSelect.value === OrganizationType.TEAM
                        }
                        onChange={() =>
                          addOrgTypeSelect?.setValue(OrganizationType.TEAM)
                        }
                      />
                    </div>
                    <div>
                      <FormLabel
                        htmlFor="team"
                        color={theme.colors.darkBlue[500]}
                      >
                        Family
                      </FormLabel>
                      <input
                        type={"radio"}
                        id={OrganizationType.FAMILY}
                        value={OrganizationType.FAMILY}
                        checked={
                          addOrgTypeSelect.value === OrganizationType.FAMILY
                        }
                        onChange={() =>
                          addOrgTypeSelect?.setValue(OrganizationType.FAMILY)
                        }
                      />
                    </div>
                  </Flex>
                )}
                {!!submissionInstructions && (
                  <Text>{submissionInstructions}</Text>
                )}
                {!!emailErrors?.length &&
                  emailErrors.map((error, index) => (
                    <Box my={2}>
                      <Text color="tomato" key={index}>
                        Error with invite to {error.email}: {error.error}
                      </Text>
                    </Box>
                  ))}
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="darkBlue"
                  isLoading={props.isSubmitting}
                  type="submit"
                >
                  {submitButtonLabel}
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}
