import * as Yup from "yup";
export const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}/, "Invalid password; check requirements")
        .required("Required"),
    newPassword: Yup.string()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}/, "Invalid password; check requirements")
        .required("Required"),
    confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"), undefined], "New password must match its confirmation")
});
export const changePasswordInitialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
};
export const changePasswordFields = [
    {
        name: "oldPassword",
        label: "Current Password",
        placeholder: "Current Password",
        initialValue: "",
        password: true
    },
    {
        name: "newPassword",
        label: "New Password",
        placeholder: "New Password",
        initialValue: "",
        password: true
    },
    {
        name: "confirmPassword",
        label: "Confirm New Password",
        placeholder: "Confirm New Password",
        initialValue: "",
        password: true
    }
];
