import React from "react";
import { Text } from "@chakra-ui/react";

interface ScreenTitleProps {
  text: string;
  noMargin?: boolean;
  centered?: boolean;
}

export function ScreenTitle({ text, noMargin, centered }: ScreenTitleProps) {
  return (
    <Text
      fontSize="2xl"
      color="title"
      margin={centered ? "0 auto" : "0px"}
      mb={noMargin ? undefined : "8px"}
      maxWidth={"90%"}
    >
      {text}
    </Text>
  );
}
