const useURLParams = (params) => {
    var _a, _b;
    const results = [];
    const deriveParamRegex = (param) => new RegExp(`${param}=([^&]*)`, "ig");
    Array.isArray(params)
        ? params.forEach(param => {
            var _a, _b;
            results.push((_b = (_a = window.location.href
                .match(deriveParamRegex(param))) === null || _a === void 0 ? void 0 : _a[0].split("=")[1]) !== null && _b !== void 0 ? _b : "");
        })
        : results.push((_b = (_a = window.location.href
            .match(deriveParamRegex(params))) === null || _a === void 0 ? void 0 : _a[0].split("=")[1]) !== null && _b !== void 0 ? _b : "");
    return results;
};
export default useURLParams;
