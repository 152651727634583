import React, { useContext } from "react";
import { UserContext } from "../context/UserContextProvider";
import { useSetCurrentOrganization } from "../hooks/useSetCurrentOrganization";
import { Loader } from "../components/Loader";
import { Redirect } from "@reach/router";

export const OrgSelectRedirect = ({ redirect }: { redirect: string }) => {
  const {
    currentUserInfo,
    currentOrganization,
    setCurrentOrganization
  } = useContext(UserContext);
  useSetCurrentOrganization({
    currentUserInfo,
    currentOrganization,
    orgIdInUrl: null,
    setCurrentOrganization
  });

  if (!currentOrganization) {
    return <Loader isLoading={true} />;
  }

  return <Redirect to={`${redirect}/${currentOrganization.organizationId}`} />;
};
