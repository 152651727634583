import React from "react";
import { Flex, Spinner, SpinnerProps } from "@chakra-ui/react";

interface LoaderProps {
  isLoading: boolean;
  size?: SpinnerProps["size"];
}

export function Loader({ isLoading, size = "xl" }: LoaderProps) {
  return isLoading ? (
    <Flex margin="0 auto">
      <Spinner color="spinner" margin="8px auto" size={size} />
    </Flex>
  ) : (
    <></>
  );
}
