export const resetPasswordFields = [
    {
        name: "email",
        label: "Email",
        placeholder: "Email",
        initialValue: ""
    },
    {
        name: "resetCode",
        label: "Reset Code",
        placeholder: "Reset Code",
        initialValue: ""
    },
    {
        name: "newPassword",
        label: "New Password",
        placeholder: "New Password",
        initialValue: "",
        password: true
    },
    {
        name: "confirmPassword",
        label: "Confirm Password",
        placeholder: "Confirm Password",
        initialValue: "",
        password: true
    }
];
