export var RouteNames;
(function (RouteNames) {
    RouteNames["root"] = "/";
    RouteNames["register"] = "register";
    RouteNames["initialRegister"] = "initialRegister";
    RouteNames["registerForPlan"] = "registerplan";
    RouteNames["organizations"] = "/organizations";
    RouteNames["unauthorized"] = "/unauthorized";
    RouteNames["profile"] = "/profile";
    RouteNames["users"] = "/users";
    RouteNames["userHistory"] = "/history";
    RouteNames["safeList"] = "/safelist";
    RouteNames["recall"] = "/recall";
    RouteNames["resetPassword"] = "/resetPassword";
    RouteNames["deactivated"] = "/deactivated";
})(RouteNames || (RouteNames = {}));
