export var MMSEventType;
(function (MMSEventType) {
    MMSEventType["RECALL_SUMMARY"] = "RECALL_SUMMARY";
    MMSEventType["RECALL_RESPONSE"] = "RECALL_RESPONSE";
    MMSEventType["PREEMPTIVE"] = "PREEMPTIVE";
})(MMSEventType || (MMSEventType = {}));
export var MMSNotificationType;
(function (MMSNotificationType) {
    MMSNotificationType["recallInitiation"] = "recallInitiation";
    MMSNotificationType["recallReminder"] = "recallReminder";
    MMSNotificationType["recallResponse"] = "recallResponse";
    MMSNotificationType["recallComplete"] = "recallComplete";
    MMSNotificationType["recallEnded"] = "recallEnded";
    MMSNotificationType["preemptive"] = "preemptive";
})(MMSNotificationType || (MMSNotificationType = {}));
