class Formatter {
    formatPhoneNumber(phoneNumber) {
        if (phoneNumber.toString().length !== 10) {
            return phoneNumber.toString();
        }
        const cleaned = ("" + String(phoneNumber)).replace(/\D/g, "");
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = match[1] ? "+1 " : "";
            return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
        }
    }
    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
}
export const formatter = new Formatter();
