import React, { useContext, useEffect, useState } from "react";
import { useAsync } from "../hooks/useAsync";
import { apiManager } from "../network/apiManager";
import { getErrorToast, getSuccessToast } from "./Toasts";
import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { InviteUserSchema } from "../forms/schemas/inviteUserSchema";
import UserRow from "./UserRow";
import { useParams } from "@reach/router";
import { UserContext } from "../context/UserContextProvider";
import { Loader } from "./Loader";
import { ModalForm } from "./ModalForm";
import { AddIcon, RepeatIcon } from "@chakra-ui/icons";
import { inviteUserFields } from "../forms/fields/inviteUserFields";
import {
  UserRole,
  ValidateSeatStatusResponse,
  ViewUserInOrgResponse
} from "../schemas/User";

export default function UserList() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [emailErrors, setEmailErrors] = useState<
    { email: string; error: string }[]
  >([]);
  const { id } = useParams();
  const { currentOrganization, setToken } = useContext(UserContext);

  const fetchUsers = async () => {
    try {
      users.clearError();
      return await apiManager.getUsers(id);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const fetchInactiveUsers = async () => {
    if (currentOrganization) {
      return await apiManager.getInactiveUsers(
        currentOrganization.organizationId,
        setToken
      );
    } else {
      return [];
    }
  };

  const handleInviteUser = async (values: { email: string }) => {
    // Clear old errors
    inviteUser.clearError();
    setEmailErrors([]);
    try {
      const emailArray = values.email.split(",").map(email => email.trim());
      if (
        validateSeatStatus.value &&
        currentOrganization &&
        emailArray.length >
          validateSeatStatus.value?.maxSeats -
            validateSeatStatus.value?.currentUsers
      ) {
        const response = await apiManager.startBillingPortalSession(
          currentOrganization.organizationId
        );
        onClose();
        toast({
          position: "top",
          isClosable: true,
          duration: null,
          render: ({ onClose }) => (
            <Flex color="white" p={8} bg="#E53E3E">
              <Box>
                You have tried to add more users than your plan has available.
                Remove some invitations, deactivate other users, or{" "}
                <Text
                  as={"a"}
                  textDecoration={"underline"}
                  href={response.url}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  add more users to your billing plan.
                </Text>
              </Box>
              <Box
                onClick={onClose}
                fontWeight={"bold"}
                fontSize={"lg"}
                _hover={{ cursor: "pointer" }}
              >
                X
              </Box>
            </Flex>
          )
        });
      } else {
        let failedEmails: string[] = [];
        if (currentOrganization) {
          const response = await apiManager.inviteUsers(
            {
              emails: emailArray,
              organizationId: currentOrganization.organizationId
            },
            setToken
          );
          failedEmails = response.failedEmails;
          await users.execute();
          if (!failedEmails.length) {
            toast(
              getSuccessToast({
                title: "Successfully invited new user(s)",
                description: "All requested invites were sent."
              })
            );
            onClose();
          } else {
            onClose();
            toast(
              getErrorToast({
                title: "Error with invites",
                description:
                  "Some or all invites failed to send. See details below."
              })
            );
            setEmailErrors(
              failedEmails.map(email => {
                return { email, error: "Email is already in use" };
              })
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const validateSeatStatusAsync = async () => {
    try {
      if (currentOrganization) {
        const res = await apiManager.validateSeatStatus(
          currentOrganization.organizationId
        );
        if (!res.roomForGrowth && currentOrganization) {
          const response = await apiManager.startBillingPortalSession(
            currentOrganization.organizationId
          );
          toast({
            position: "top",
            isClosable: true,
            duration: null,
            render: ({ onClose }) => (
              <Flex color="white" p={8} bg="#E53E3E">
                <Box mr={2}>
                  You have tried to add more users than your plan has available.
                  Remove some invitations, deactivate other users, or{" "}
                  <Text
                    as={"a"}
                    textDecoration={"underline"}
                    href={response.url}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                  >
                    add more users to your billing plan.
                  </Text>{" "}
                </Box>
                <Box
                  onClick={onClose}
                  fontWeight={"bold"}
                  fontSize={"lg"}
                  _hover={{ cursor: "pointer" }}
                >
                  X
                </Box>
              </Flex>
            )
          });
        } else {
          onOpen();
        }
        return res;
      }
    } catch (err) {
      console.log(err);
      if (err.name === "PLAN_USER_LIMIT_EXCEEDED" && currentOrganization) {
        const response = await apiManager.startBillingPortalSession(
          currentOrganization.organizationId
        );
        toast({
          position: "top",
          isClosable: true,
          duration: null,
          render: ({ onClose }) => (
            <Flex color="white" p={8} bg="#E53E3E">
              <Box>
                You have no more users available on your plan. Deactivate some
                users or{" "}
                <Text
                  as={"a"}
                  textDecoration={"underline"}
                  href={response.url}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  add more users to your billing plan.
                </Text>
              </Box>
              <Box
                onClick={onClose}
                fontWeight={"bold"}
                fontSize={"lg"}
                _hover={{ cursor: "pointer" }}
              >
                X
              </Box>
            </Flex>
          )
        });
      }
      throw err;
    }
  };

  const users = useAsync<ViewUserInOrgResponse[]>(
    fetchUsers,
    "Error retrieving user list. Check your connection and try again."
  );

  const inviteUser = useAsync<void>(handleInviteUser);
  const validateSeatStatus = useAsync<ValidateSeatStatusResponse | undefined>(
    validateSeatStatusAsync
  );
  const inactiveUsers = useAsync<ViewUserInOrgResponse[]>(fetchInactiveUsers);

  useEffect(() => {
    users.execute();
    return () => {
      inviteUser.clearError();
      inactiveUsers.clearError();
      toast.closeAll();
    };
  }, []);

  useEffect(() => {
    if (users.error) {
      toast(getErrorToast({ description: users.error }));
    }
  }, [users.error]);

  const seatsAvailable =
    (validateSeatStatus.value &&
      validateSeatStatus.value.maxSeats -
        validateSeatStatus.value.currentUsers) ??
    "N/A";

  return (
    <>
      <Flex align="flex-end" direction="column">
        <Flex direction="row" justify={"space-between"} w={"100%"}>
          <Button
            colorScheme="darkBlue"
            w={"40px"}
            onClick={() => {
              users.execute();
              inactiveUsers.execute();
            }}
            mb={2}
          >
            <RepeatIcon />
          </Button>
          {validateSeatStatus.pending ? (
            <Loader isLoading={validateSeatStatus.pending} />
          ) : (
            <Button
              colorScheme="darkBlue"
              w={["200px", "200px", "150px", "150px"]}
              leftIcon={<AddIcon />}
              onClick={validateSeatStatus.execute}
              mb={2}
            >
              Invite User
            </Button>
          )}
        </Flex>
        <Tabs w="100%" variant="unstyled">
          <TabList>
            <Tab
              _selected={{ color: "darkBlue.500", bg: "lime.500" }}
              maxW={"23%"}
            >
              Active Users
            </Tab>
            <Tab
              _selected={{ color: "darkBlue.500", bg: "lime.500" }}
              maxW={"23%"}
            >
              Admins
            </Tab>
            <Tab
              _selected={{ color: "darkBlue.500", bg: "lime.500" }}
              maxW={"23%"}
            >
              Non-admins
            </Tab>
            <Tab
              _selected={{ color: "darkBlue.500", bg: "lime.500" }}
              maxW={"23%"}
              onClick={inactiveUsers.execute}
            >
              Inactive Users
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box
                borderWidth="1px"
                rounded="md"
                w="100%"
                m="10px"
                overflowX={"auto"}
                maxH={window.innerHeight * 0.5}
              >
                {users.value?.map(user => (
                  <UserRow
                    key={user.userId}
                    user={user}
                    validateSeatStatus={validateSeatStatusAsync}
                    refreshUsers={users.execute}
                    refreshInactiveUsers={inactiveUsers.execute}
                  />
                ))}
              </Box>
            </TabPanel>
            <TabPanel>
              <Box
                borderWidth="1px"
                rounded="md"
                w="100%"
                m="10px"
                overflowX={"auto"}
                maxH={window.innerHeight * 0.5}
              >
                {users.value
                  ?.filter(user => user.userRole === UserRole.ADMIN)
                  .map((user, i) => (
                    <UserRow
                      key={i}
                      user={user}
                      refreshUsers={users.execute}
                      validateSeatStatus={validateSeatStatusAsync}
                      refreshInactiveUsers={inactiveUsers.execute}
                    />
                  ))}
              </Box>
            </TabPanel>
            <TabPanel>
              <Box
                borderWidth="1px"
                rounded="md"
                w="100%"
                m="10px"
                overflowX={"auto"}
                maxH={window.innerHeight * 0.5}
              >
                {users.value
                  ?.filter(user => user.userRole === UserRole.MEMBER)
                  .map(user => (
                    <UserRow
                      key={user.userId}
                      user={user}
                      validateSeatStatus={validateSeatStatusAsync}
                      refreshUsers={users.execute}
                      refreshInactiveUsers={inactiveUsers.execute}
                    />
                  ))}
              </Box>
            </TabPanel>
            <TabPanel>
              {inactiveUsers.pending ? (
                <Loader isLoading={inactiveUsers.pending} />
              ) : (
                <Box
                  borderWidth="1px"
                  rounded="md"
                  w="100%"
                  m="10px"
                  overflowX={"auto"}
                  maxH={window.innerHeight * 0.5}
                >
                  {!!inactiveUsers.value &&
                    inactiveUsers.value.map(user => (
                      <UserRow
                        key={user.userId}
                        user={user}
                        validateSeatStatus={validateSeatStatus}
                        refreshUsers={users.execute}
                        refreshInactiveUsers={inactiveUsers.execute}
                      />
                    ))}
                </Box>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <ModalForm
        handleSubmit={inviteUser.execute}
        fields={inviteUserFields}
        title="Invite New User"
        validationSchema={InviteUserSchema}
        emailErrors={emailErrors}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setEmailErrors([]);
        }}
        submitButtonLabel="Invite"
        submissionInstructions={`You have ${seatsAvailable} seat(s) available. Invite multiple users by separating emails with commas. All users will be invited as members and can be upgraded to admins after invitations are sent.`}
      />
    </>
  );
}
