import { useEffect, useState } from "react";
import { getErrorToast } from "../components/Toasts";
import { useToast } from "@chakra-ui/react";
export const useLocalError = (errorArray) => {
    const [localError, setLocalError] = useState("");
    const toast = useToast();
    useEffect(() => {
        errorArray.forEach(error => {
            if (error) {
                setLocalError(error);
            }
        });
    }, errorArray);
    useEffect(() => {
        if (localError) {
            toast(getErrorToast({ description: localError }));
        }
    }, [localError]);
    useEffect(() => {
        return () => toast.closeAll();
    }, []);
    return { localError, setLocalError };
};
