import React, { useContext, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { apiManager } from "../network/apiManager";
import { useAsync } from "../hooks/useAsync";
import theme from "../theme";
import { formatter } from "../utils/formatter";
import { ConfirmationModal } from "./ConfirmationModal";
import { getSuccessToast } from "./Toasts";
import { UserContext } from "../context/UserContextProvider";
import { ViewIcon } from "@chakra-ui/icons";
import { useCurrentWidth } from "../hooks/useCurrentWidth";
import { useLocalError } from "../hooks/useLocalError";
import { UserRole, UserStatus, ViewUserInOrgResponse } from "../schemas/User";
import { OrgStatus } from "../schemas/Organization";
import { EventDescription, MMSEvent } from "../schemas/Event";
import { EventList } from "./EventList";
import { Loader } from "./Loader";

interface Props {
  user: ViewUserInOrgResponse;
  refreshUsers: (values?: any) => void;
  refreshInactiveUsers: (values?: any) => void;
  validateSeatStatus: any;
}

interface State {
  selectedEvent: EventDescription | null;
  onInitialQuery: boolean;
  emptyResults: boolean;
  fullList: MMSEvent[];
  currentLastKey: string;
}

const initialState: State = {
  selectedEvent: null,
  onInitialQuery: true,
  emptyResults: false,
  fullList: [],
  currentLastKey: ""
};

export default function UserRow({
  user,
  refreshUsers,
  refreshInactiveUsers
}: Props) {
  const userDrawer = useDisclosure();
  const confirmationToggleStatus = useDisclosure();
  const confirmationChangeRole = useDisclosure();
  const toast = useToast();
  const { currentUserInfo, currentOrganization, setToken } = useContext(
    UserContext
  );
  const width = useCurrentWidth();
  const [state, setState] = useState<State>(initialState);

  const validateSeatStatusAsync = async () => {
    try {
      if (currentOrganization) {
        const res = await apiManager.validateSeatStatus(
          currentOrganization.organizationId
        );
        if (!res.roomForGrowth && currentOrganization) {
          throw { name: "PLAN_USER_LIMIT_EXCEEDED" };
        }
      }
    } catch (err) {
      console.log(err);
      if (err.name === "PLAN_USER_LIMIT_EXCEEDED" && currentOrganization) {
        const response = await apiManager.startBillingPortalSession(
          currentOrganization.organizationId
        );
        toast({
          position: "top",
          isClosable: true,
          duration: null,
          render: ({ onClose }) => (
            <Flex color="white" p={8} bg="#E53E3E">
              <Box>
                Your billing plan is full. To activate this user, please
                deactivate other users or{" "}
                <Text
                  as={"a"}
                  textDecoration={"underline"}
                  href={response.url}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  add more users to your billing plan.
                </Text>
              </Box>
              <Box
                onClick={onClose}
                fontWeight={"bold"}
                fontSize={"lg"}
                _hover={{ cursor: "pointer" }}
              >
                X
              </Box>
            </Flex>
          )
        });
        confirmationToggleStatus.onClose();
      }
      throw err;
    }
  };

  const toggleUserActivation = async () => {
    try {
      if (user.status === UserStatus.DEACTIVATED) {
        await validateSeatStatusAsync();
      }
      confirmationToggleStatus.onClose();
      if (currentOrganization?.organizationId) {
        await apiManager.toggleUserActivation({
          organizationId: currentOrganization?.organizationId,
          status:
            user.status === UserStatus.ACTIVE ||
            user.status === UserStatus.PENDING
              ? UserStatus.DEACTIVATED
              : UserStatus.ACTIVE,
          email: user.email
        });
        await refreshUsers();
        await refreshInactiveUsers();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const toggleUserRole = async () => {
    confirmationChangeRole.onClose();
    if (currentOrganization?.organizationId) {
      await apiManager.toggleUserRole({
        organizationId: currentOrganization?.organizationId,
        email: user.email,
        userRole:
          user.userRole === UserRole.MEMBER ? UserRole.ADMIN : UserRole.MEMBER,
        setToken
      });
    }
    await refreshUsers();
  };

  const resendUserInvitation = async ({
    email,
    organizationId
  }: {
    email: string;
    organizationId: string;
  }) => {
    await apiManager.resendInvite(email, organizationId, setToken);
    toast(
      getSuccessToast({
        title: "Successful Invite",
        description: `A new email invitation has been sent`
      })
    );
  };

  const toggleActivation = useAsync<void>(
    toggleUserActivation,
    "Error changing user status, please refresh and try again."
  );
  const toggleRole = useAsync<void>(
    toggleUserRole,
    "Error changing user role, please refresh and try again."
  );
  const resendInvitation = useAsync<void>(
    resendUserInvitation,
    "Error sending your invitation. Please double check the email address and your connection"
  );

  const getHistoryAsync = async () => {
    try {
      if (currentOrganization) {
        const results = await apiManager.getUserEventsInOrg({
          organizationId: currentOrganization.organizationId,
          email: user.email,
          setToken
        });
        setState(prevState => {
          return {
            ...prevState,
            currentLastKey: results.lastSortKey ?? "",
            fullList: results.events,
            onInitialQuery: false,
            emptyResults: !results.events.length
          };
        });
      } else {
        throw "Unauthorized";
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const getHistory = useAsync<void>(getHistoryAsync);

  const getMoreResults = async () => {
    try {
      if (currentOrganization) {
        const { fullList } = state;
        if (
          !state.emptyResults &&
          !state.onInitialQuery &&
          fullList?.length &&
          state.currentLastKey
        ) {
          const results = await apiManager.getUserEventsInOrg({
            organizationId: currentOrganization.organizationId,
            email: user.email,
            lastKey: state.currentLastKey,
            setToken
          });
          if (!results.events.length) {
            setState(prevState => {
              return { ...prevState, emptyResults: true, currentLastKey: "" };
            });
          }
          setState(prevState => {
            return {
              ...prevState,
              fullList: [...prevState.fullList, ...results.events],
              currentLastKey: results.lastSortKey ?? ""
            };
          });
        }
      }
    } catch (err) {
      console.log("error in get more results", err);
      throw err;
    }
  };

  useLocalError([
    toggleRole.error,
    toggleActivation.error,
    resendInvitation.error
  ]);

  return (
    <Box
      px={4}
      py={2}
      borderBottomWidth="1px"
      _last={{ borderBottomWidth: 0 }}
      w="100%"
      textAlign="left"
      h="75px"
      _hover={{ borderColor: "gray.200", bg: "lime.500" }}
      cursor="pointer"
      onClick={() => {
        userDrawer.onOpen();
        getHistory.execute();
      }}
    >
      <Flex justify="space-between" alignItems="center">
        <Flex direction="row" alignItems="center" justifyContent={"center"}>
          {user.status !== UserStatus.PENDING && (
            <Avatar
              src={user.presignedUrl ?? ""}
              name={`${user.firstName} ${user.lastName}`}
              size="md"
              mr={4}
            />
          )}
          <strong>
            <Text fontSize={["sm", "md", "lg", "xl"]}>
              {user.status === UserStatus.PENDING
                ? user.email
                : `${user.firstName} ${user.lastName}`}
            </Text>
            {user.status === UserStatus.PENDING && (
              <Badge colorScheme="orange" color={theme.colors.darkBlue[500]}>
                Pending
              </Badge>
            )}
          </strong>
        </Flex>
        <ViewIcon />
      </Flex>
      <Drawer
        isOpen={userDrawer.isOpen}
        placement="right"
        onClose={userDrawer.onClose}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton w={"40px"} focusBorderColor={"lime"} />
          <DrawerHeader color={theme.colors.darkBlue[500]}>
            <Flex direction="column" align={"center"}>
              <Avatar
                src={user.presignedUrl ?? ""}
                name={
                  user.status !== UserStatus.PENDING
                    ? `${user.firstName} ${user.lastName}`
                    : undefined
                }
                size="2xl"
                mb={8}
                mr={4}
              />
              {user.status !== UserStatus.PENDING && (
                <Text
                  fontSize={"xl"}
                >{`${user.firstName} ${user.lastName}`}</Text>
              )}
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Flex
              direction={width > 500 ? "row" : "column"}
              justify="space-between"
              mb={8}
            >
              <Button
                colorScheme="darkBlue"
                onClick={confirmationToggleStatus.onOpen}
                isDisabled={user.userId === currentUserInfo?.currentUser.userId}
                mb={2}
              >
                {user.status === UserStatus.PENDING
                  ? "Remove User"
                  : user.status === UserStatus.DEACTIVATED
                  ? "Activate User"
                  : "Deactivate User"}
              </Button>
              <Button
                colorScheme="orange"
                onClick={confirmationChangeRole.onOpen}
                isDisabled={
                  user.status === UserStatus.DEACTIVATED ||
                  user.userId === currentUserInfo?.currentUser.userId
                }
                mb={2}
              >
                {user.userRole === UserRole.ADMIN
                  ? "Remove Admin Status"
                  : "Grant Admin Status"}
              </Button>
            </Flex>
            <Text color={theme.colors.darkBlue[500]} fontWeight={"bold"}>
              User Information
            </Text>
            {user.status !== UserStatus.PENDING && (
              <Text color={theme.colors.darkBlue[500]}>
                Phone:{" "}
                {user.phoneNumber &&
                  formatter.formatPhoneNumber(user.phoneNumber)}
              </Text>
            )}
            <Text color={theme.colors.darkBlue[500]}>Email: {user.email}</Text>
            {user.status === UserStatus.PENDING &&
              currentOrganization?.active === OrgStatus.ACTIVE && (
                <Button
                  variant="link"
                  onClick={() =>
                    resendInvitation.execute({
                      email: user.email,
                      organizationId: currentOrganization?.organizationId ?? ""
                    })
                  }
                  ml={2}
                >
                  Resend Invitation
                </Button>
              )}
            <Text color={theme.colors.darkBlue[500]} fontWeight={"bold"}>
              User's Safe List
            </Text>
            {getHistory.pending ? (
              <Loader isLoading={getHistory.pending} />
            ) : !!state.fullList.length ? (
              <Box p={2} borderColor={"gray"} borderWidth={1}>
                <EventList
                  events={state.fullList}
                  onLoadMore={getMoreResults}
                  moreEvents={!!state.currentLastKey}
                />
              </Box>
            ) : (
              <Text>No events found</Text>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={userDrawer.onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <ConfirmationModal
        handleSubmit={toggleActivation.execute}
        bodyText={
          toggleActivation.pending ? (
            <Loader isLoading={true} />
          ) : (
            `Are you sure you want to 
            ${
              user.status === UserStatus.PENDING
                ? "delete"
                : user.status === UserStatus.DEACTIVATED
                ? "activate"
                : "deactivate"
            } 
            ${
              user.status === UserStatus.PENDING
                ? "this user?"
                : `${user.firstName} ${user.lastName}?`
            }`
          )
        }
        isOpen={confirmationToggleStatus.isOpen}
        onClose={confirmationToggleStatus.onClose}
      />
      <ConfirmationModal
        handleSubmit={toggleRole.execute}
        bodyText={`Are you sure you want to ${
          user.userRole === UserRole.ADMIN
            ? "remove admin status from"
            : "grant admin status to"
        } this user?`}
        isOpen={confirmationChangeRole.isOpen}
        onClose={confirmationChangeRole.onClose}
      />
    </Box>
  );
}
