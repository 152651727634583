export function getErrorToast({ title = "Error", description }) {
    return {
        title,
        description,
        status: "error",
        duration: null,
        isClosable: true,
        position: "top"
    };
}
export function getSuccessToast({ title = "Success", description }) {
    return {
        title,
        description,
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top"
    };
}
