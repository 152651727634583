import React from "react";
import { UseToastOptions } from "@chakra-ui/react";

interface GetToastProps {
  title?: string;
  description: string;
}

export function getErrorToast({
  title = "Error",
  description
}: GetToastProps): UseToastOptions {
  return {
    title,
    description,
    status: "error",
    duration: null,
    isClosable: true,
    position: "top"
  };
}

export function getSuccessToast({
  title = "Success",
  description
}: GetToastProps): UseToastOptions {
  return {
    title,
    description,
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top"
  };
}
