import React, { useContext, useEffect, useMemo, useRef } from "react";
import { CardBackground } from "../components/ViewContainers";
import { ScreenTitle } from "../components/ScreenTitles";
import { Avatar, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { UserContext } from "../context/UserContextProvider";
import { useAsync } from "../hooks/useAsync";
import { apiManager } from "../network/apiManager";
import { Loader } from "../components/Loader";
import { useParams } from "@reach/router";
import { OrganizationUpdatesSchema } from "../forms/schemas/organizationUpdatesSchema";
import UserList from "../components/UserList";
import { ModalForm } from "../components/ModalForm";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useLocalError } from "../hooks/useLocalError";
import { ViewOrganizationResponse } from "../schemas/Organization";
import { useDeactivated } from "../hooks/useDeactivated";
import { useSetCurrentOrganization } from "../hooks/useSetCurrentOrganization";

export default function OrganizationDetails() {
  const {
    currentUserInfo,
    setCurrentOrganization,
    currentOrgLogoPresignedUrl,
    setCurrentOrgLogoPresignedUrl,
    currentOrganization,
    setToken
  } = useContext(UserContext);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const orgUpdatesModal = useDisclosure();
  const orgDeleteConfirmationModal = useDisclosure();
  const toast = useToast();
  const { id } = useParams();
  useDeactivated(currentUserInfo, currentOrganization);
  const { id: orgIdInUrl } = useParams();
  useSetCurrentOrganization({
    currentUserInfo,
    currentOrganization,
    orgIdInUrl,
    setCurrentOrganization
  });

  useEffect(() => {
    organization.execute({});
    return () => {
      orgUpdates.clearError();
      organization.clearError();
      updateLogo.clearError();
      toast.closeAll();
    };
  }, []);

  const fetchOrganization = async () => {
    try {
      organization.clearError();
      return await apiManager.getOrganization(id);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const fetchUpdateLogo = async (file: File) => {
    try {
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/tiff"
      ) {
        throw new Error("Logo must be a jpg, png, or tiff file type");
      }
      if (currentOrganization && setCurrentOrgLogoPresignedUrl) {
        const newPresignedUrl = await apiManager.uploadOrgLogoImage({
          file,
          id: currentOrganization.organizationId,
          setToken
        });
        setCurrentOrgLogoPresignedUrl(newPresignedUrl.presignedUrl);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteOrgLogo = async () => {
    try {
      if (currentOrganization) {
        await apiManager.deleteOrgLogo(
          currentOrganization.organizationId,
          setToken
        );
      }
      orgDeleteConfirmationModal.onClose();
      setCurrentOrgLogoPresignedUrl("");
    } catch (err) {
      console.log(err);
    }
  };

  const updateOrg = async (values: { name: string }) => {
    orgUpdates.clearError();
    orgUpdatesModal.onClose();
    if (currentOrganization) {
      await apiManager.updateOrganization(
        currentOrganization.organizationId,
        values,
        setToken
      );
      await organization.execute();
      setCurrentOrganization(organization.value);
    }
  };

  const handleFileChange = () => {
    if (inputFileRef.current?.files?.length) {
      updateLogo.execute(inputFileRef.current.files[0]);
    }
  };

  const organization = useAsync<ViewOrganizationResponse>(
    fetchOrganization,
    "Error retrieving organization. Check your connection and try again."
  );

  const updateLogo = useAsync<void>(
    fetchUpdateLogo,
    "Error updating logo. Check your connection and try again."
  );

  const orgUpdates = useAsync<void>(
    updateOrg,
    "Error updating organization; please check your connection and try again"
  );

  const deleteLogo = useAsync<void>(
    deleteOrgLogo,
    "Error deleting logo, please check your connection and try again"
  );

  useLocalError([
    orgUpdates.error,
    updateLogo.error,
    organization.error,
    deleteLogo.error
  ]);

  const organizationUpdatesFields = useMemo(() => {
    return [
      {
        name: "name",
        label: "Name",
        placeholder: "Name",
        initialValue: organization.value?.name ?? ""
      }
    ];
  }, [organization]);

  return (
    <>
      <>
        <CardBackground>
          {updateLogo.pending || organization.pending || deleteLogo.pending ? (
            <Loader
              isLoading={
                updateLogo.pending || organization.pending || deleteLogo.pending
              }
            />
          ) : (
            <>
              <Flex
                direction={"column"}
                justify={"space-around"}
                align={"center"}
              >
                <Flex d="row">
                  <Avatar
                    src={
                      currentOrgLogoPresignedUrl
                        ? currentOrgLogoPresignedUrl
                        : ""
                    }
                    size="xl"
                    mb={2}
                  />
                  <EditIcon
                    mt={3}
                    ml={1}
                    onClick={() => inputFileRef.current?.click()}
                  />
                  {!!currentOrgLogoPresignedUrl && (
                    <DeleteIcon
                      mt={3}
                      ml={1}
                      onClick={orgDeleteConfirmationModal.onOpen}
                    />
                  )}
                  <input
                    style={{ opacity: 0, width: 0, height: 0 }}
                    type="file"
                    ref={inputFileRef}
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Flex>
                <Flex
                  align={"center"}
                  direction={"row"}
                  justify={"start"}
                  maxW={"96%"}
                >
                  <ScreenTitle text={organization.value?.name ?? ""} />
                  <EditIcon ml={2} mt={1} onClick={orgUpdatesModal.onOpen} />
                </Flex>
              </Flex>
            </>
          )}
          <UserList />
        </CardBackground>
      </>
      <ModalForm
        handleSubmit={orgUpdates.execute}
        fields={organizationUpdatesFields}
        title="Update Name"
        validationSchema={OrganizationUpdatesSchema}
        isOpen={orgUpdatesModal.isOpen}
        onClose={orgUpdatesModal.onClose}
      />
      <ConfirmationModal
        handleSubmit={deleteLogo.execute}
        bodyText="Are you sure you want to remove your logo?"
        isOpen={orgDeleteConfirmationModal.isOpen}
        onClose={orgDeleteConfirmationModal.onClose}
      />
    </>
  );
}
