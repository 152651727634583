import { navigate } from "@reach/router";
import { RouteNames } from "./routeNames";
import { UserRole } from "../schemas/User";
import { OrgStatus } from "../schemas/Organization";
export function navigateUserBasedOnRole(user) {
    // only a member (single or multiple orgs)
    if (!user.userInOrgItems.find(userItem => userItem.userRole === UserRole.ADMIN)) {
        navigate("/");
        // admin of single active org - go to that org's user list
    }
    else if (user.organizations.length === 1 &&
        user.organizations.find(org => org.active === OrgStatus.ACTIVE)) {
        navigate(RouteNames.users + "/" + user.organizations[0].organizationId);
        // admin of single inactive org OR admin of multiple orgs - go to the orgs list page to pay for it
    }
    else {
        navigate(RouteNames.organizations);
    }
}
