import { useCallback, useState } from "react";
export function useAsync(asyncFunction, errorMessage = "Error fetching your data") {
    const [pending, setPending] = useState(false);
    const [value, setValue] = useState(null);
    const [error, setError] = useState(null);
    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback((values) => {
        setPending(true);
        setValue(null);
        setError(null);
        if (values) {
            return asyncFunction(values)
                .then(response => setValue(response))
                .catch(err => {
                setError(errorMessage);
            })
                .finally(() => setPending(false));
        }
        return asyncFunction()
            .then(response => setValue(response))
            .catch(err => {
            setError(errorMessage);
        })
            .finally(() => setPending(false));
    }, [asyncFunction]);
    return { execute, pending, value, error, clearError: () => setError(null) };
}
export var CustomErrorType;
(function (CustomErrorType) {
    CustomErrorType["DATA_VALIDATION_ERROR"] = "DATA_VALIDATION_ERROR";
    CustomErrorType["EMAIL_ACCOUNT_EXISTS"] = "EMAIL_ACCOUNT_EXISTS";
    CustomErrorType["INVALID_INVITATION"] = "INVALID_INVITATION";
    CustomErrorType["INVALID_CREDENTIALS"] = "INVALID_CREDENTIALS";
    CustomErrorType["UNAUTHORIZED"] = "UNAUTHORIZED";
    CustomErrorType["NO_RESULTS"] = "NO_RESULTS";
    CustomErrorType["INTERNAL_ERROR"] = "INTERNAL_ERROR";
})(CustomErrorType || (CustomErrorType = {}));
