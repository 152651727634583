import * as Yup from "yup";
export const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    password: Yup.string().required("Required")
});
export const loginFields = [
    {
        name: "email",
        placeholder: "Email",
        label: "Email",
        initialValue: ""
    },
    {
        name: "password",
        placeholder: "Password",
        label: "Password",
        initialValue: "",
        password: true
    }
];
