var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiUrl } from "../environment";
const slashifyUrlStart = (url) => url[0] === "/" ? url : `/${url}`;
const prependApiUrl = (endpoint) => `${apiUrl[process.env.REACT_APP_ENV]}${slashifyUrlStart(endpoint)}`;
export default function FetchWrapper(request) {
    return __awaiter(this, void 0, void 0, function* () {
        const { path, body, method = "GET", headers: suppliedHeaders = {}, cache = "default", mode = "cors", token = null, redirect = "follow", referrer = "client" } = request;
        if (!path) {
            throw new Error(`'path' property not specified for fetch call`);
        }
        const url = prependApiUrl(path);
        const fetchConfig = {
            headers: buildRequestHeaders(suppliedHeaders, token),
            cache,
            method,
            mode,
            redirect,
            referrer
        };
        if (method !== "GET") {
            fetchConfig.body = JSON.stringify(body);
        }
        const response = yield fetch(url, fetchConfig);
        if (response.ok) {
            return response.json();
        }
        throw yield response.json();
    });
}
function buildRequestHeaders(additionalHeaders, token) {
    const authHeaders = token ? { Authorization: token } : {};
    return Object.assign(Object.assign({ Accept: "application/json", "Content-Type": "application/json" }, authHeaders), additionalHeaders);
}
